<template>
  <div class="topbar flex justify-center items-end">
    <div class="z-999 flex justify-between items-center fontbody1" style="margin-bottom: 20px">
      <div class="flex">
        <img v-if="imgUrl == false" class="cursor-pointer" style="height: 35px" src="../assets/img/logo-wo-back.png"
          alt="" @click="$router.push('home')" />
        <img v-if="imgUrl == true" class="cursor-pointer" style="height: 35px" src="../assets/img/logo-wo-back.png"
          alt="" @click="$router.push('home')" />
        <div class="topbar-router ml-10 flex flex-nowrap items-end">
          <span id="/home" @click="changeEvent" class="cursor-pointer">{{
              $t("topbar.home")
          }}</span>
          <span id="/shuttle" @click="changeEvent" class="cursor-pointer">{{
              $t("topbar.shuttle")
          }}</span>
          <span id="/insight" @click="changeEvent" class="cursor-pointer">{{
              $t("topbar.insight")

          }}</span>
          <span id="/aboutUs" @click="changeEvent" class="cursor-pointer">
            {{ $t("topbar.about") }}
          </span>
          <span id="/FAQ" @click="changeEvent" class="fontbottom cursor-pointer">{{
              $t("bottombar.faq")
          }}</span>
          <span id="/contact" @click="changeEvent" class="cursor-pointer">{{
              $t("topbar.contact")
          }}</span>
          <!--      <span @click="changeLanguage">语言切换/{{ $t("language.name") }}</span>-->

          <!-- <div class="icon">
          <span style="color: white" class="iconfont icon-linkedin cursor-pointer" @click="tolink"></span>
          <span style="color: white" class="iconfont icon-twitter cursor-pointer" @click="totw"></span>
          <span style="color: white" class="iconfont icon-facebook-fill cursor-pointer" @click="tofb"></span>
          <span style="color: white" class="iconfont icon-Youtube-fill cursor-pointer" @click="toyoutube"></span>
        </div> -->
          <!--        <span style="color: white" class="iconfont icon-instagram-fill"></span>-->
        </div>
      </div>
      <div class="flex items-center topbar-router" style="padding-left: 16px;padding-top: 10px">
<!--        <i style="color: white;font-size: 20px" class="iconfont icon-quanqiu cursor-pointer"></i>-->
        <img src="../assets/img/earth.png" style="width: 20px;display: inline-block"></img>
        <span style="margin-left: 11px" @click="changeLanguage" ref="english" class="cursor-pointer">EN</span><span
          style="margin-left: 8px" @click="changeLanguage" ref="chinese" class="cursor-pointer">CN</span>

      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  mounted() {
    window.onresize = () => {
      // console.log(document.body.clientWidth);
      this.imgUrl = document.body.clientWidth > 992;
    };
    this.ifphone();
    if (this.$route.path.substr(0, 8) === "/insight") {
      document.getElementById("/insight").style.color = "#f7b32c";
    } else document.getElementById(this.$route.path).style.color = "#f7b32c";
    //初始语言
    let currentLang = Cookies.get("language");
    if (currentLang == "en") {
      this.$refs.english.style.setProperty("color", "#f7b52c");
    } else if (currentLang == "zh") {
      this.$refs.chinese.style.setProperty("color", "#f7b52c");
    }
  },
  data() {
    return {
      imgUrl: true,
    };
  },
  watch: {
    "$route.path": {
      handler: function () {
        if (
          this.$route.path != "/insight_1" &&
          this.$route.path != "/insight_2" &&
          this.$route.path != "/insight_3"
        ) {
          document.querySelector("[id='/home']").style.color = "#fff";
          document.querySelector("[id='/shuttle']").style.color = "#fff";
          document.querySelector("[id='/insight']").style.color = "#fff";
          document.querySelector("[id='/contact']").style.color = "#fff";
          document.querySelector("[id='/FAQ']").style.color = "#fff";
          document.querySelector("[id='/aboutUs']").style.color = "#fff";
          document.getElementById(this.$route.path).style.color = "#f7b32c";
        }
      },
      deep: true,
    },
  },
  methods: {
    tolink() {
      window.open("https://www.linkedin.com/company/zima-dynamic-llc/");
    },
    tofb() {
      window.open("https://www.facebook.com/Zima-Dynamic-LLC-112120171596759");
    },
    toyoutube() {
      window.open("https://www.youtube.com/channel/UC9UYW0pGJdkcIEiGMNgTRVw");
    },
    totw() {
      window.open("https://twitter.com/ZimaDynamicLLC");
    },
    // changeLanguage() {
    //   console.log(Cookies.get("language"));
    //   let currentLang = Cookies.get("language");
    //   if (currentLang === "zh") {
    //     this.$i18n.locale = "en";
    //     Cookies.set("language", "en");
    //   } else if (currentLang === "en") {
    //     this.$i18n.locale = "zh";
    //     Cookies.set("language", "zh");
    //   }
    // },
    changeLanguage(e) {
      this.$refs.english.style.setProperty("color", "#FFF");
      this.$refs.chinese.style.setProperty("color", "#FFF");
      console.log(e.target.innerText);
      if (e.target.innerText == "EN") {
        console.log("English");
        this.$i18n.locale = "en";
        Cookies.set("language", "en");
        this.$refs.english.style.setProperty("color", "#f7b52c");
      } else if (e.target.innerText == "CN") {
        this.$i18n.locale = "zh";
        Cookies.set("language", "zh");
        this.$refs.chinese.style.setProperty("color", "#f7b52c");
      }
      this.$eventBus.$emit("changeLanguage", this.$i18n.locale);

      this.switchFont();
      document
        .getElementsByClassName("fontbody")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    switchFont() {
      let currentLang = Cookies.get("language");
      if (currentLang == "zh") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "Microsoft JhengHei";
        console.log(document.body.style);
      }
      if (currentLang == "en") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "OpenSans";
        console.log(document.body.style);
      }
    },
    ifphone() {
      if (document.body.clientWidth <= 992) {
        this.imgUrl = false;
        console.log("x", this.imgUrl);
      }
    },
    //路由跳转加切换tab style
    changeEvent($e) {
      console.log($e);
      console.log($e.target.parentNode.children);
      let arr = $e.target.parentNode.children;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].nodeName == "SPAN") {
          arr[i].style.color = "#fff";
        }
      }
      $e.target.style.color = "#f7b32c";
      this.$router.push($e.target.id);
    },

    // clickDiv(divname) {
    //   console.log(divname);
    //   switch (divname) {
    //     case "home":
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: "index" });
    //       }
    //       document.getElementById("/index").style.color = "#f7b32c";
    //       document.getElementById("/shuttle").style.color = "#fff";
    //       document.getElementById("/insight").style.color = "#fff";
    //       document.getElementById("/contact").style.color = "#fff";
    //       break;
    //     case "shuttle":
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: "shuttle" });
    //       }
    //       document.getElementById("/index").style.color = "#fff";
    //       document.getElementById("/shuttle").style.color = "#f7b32c";
    //       document.getElementById("/insight").style.color = "#fff";
    //       document.getElementById("/contact").style.color = "#fff";
    //       break;
    //     case "insight":
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: "insight" });
    //       }
    //       document.getElementById("/index").style.color = "#fff";
    //       document.getElementById("/shuttle").style.color = "#fff";
    //       document.getElementById("/insight").style.color = "#f7b32c";
    //       document.getElementById("/contact").style.color = "#fff";
    //       break;
    //     case "contact":
    //       if (this.$route.path !== divname) {
    //         if (this.$route.path === "/index") {
    //           document
    //             .getElementsByClassName("screenbox4")[0]
    //             .scrollIntoView({ behavior: "smooth" });
    //           return;
    //         } else {
    //           this.$router.push({ path: "contact" });
    //           document.getElementById("/index").style.color = "#fff";
    //           document.getElementById("/shuttle").style.color = "#fff";
    //           document.getElementById("/insight").style.color = "#fff";
    //           document.getElementById("/contact").style.color = "#f7b32c";
    //         }
    //       }
    //       break;
    //   }
    //   document
    //     .getElementsByClassName("fontbody")[0]
    //     .scrollIntoView({ block: "start" });
    // },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="less" scoped>
.fontbottom {
  font-family: "OpenSans";
  //letter-spacing: 2px;
}

.topbar {
  position: fixed;
  width: 100%;
  height: 120px;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.topbar {
  color: white;
}

.icon {
  margin-left: 32px;
  font-size: 20px;

  i {
    margin-left: 8px;
  }

  span {
    font-size: 20px;
  }
}

.fontbody1 {
  font-family: "OpenSans";
  //letter-spacing: 0px;
  width: 1320px;
  max-width: 1320px;
}

.topbar-right {}

.topbar-right span {
  font-size: 18px;
  margin-left: 24px;
}

.topbar-right>span {
  transition: color 0.3s;

  &:hover {
    color: #ffce6e !important;
  }
}

.topbar-router span {
  font-size: 20px;
  font-weight: lighter;
  margin-left: 24px;
}

.topbar-router>span {
  transition: color 0.3s;

  &:hover {
    color: #ffce6e !important;
  }
}
</style>
