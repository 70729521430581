<template>
  <div class="fontbody">
    <div style="background-color:#000;height:120px"></div>
    <div style="margin-top: 120px;" class="flex justify-center">
      <div class="flex main-msg ">
        <div class="lastcontent fontbody" ref="screenbox4">
          <h1 class="fontwe" style="font-size: 48px">
            <strong>{{ $t("index.screen4.title") }}</strong>
          </h1>
          <p style="margin-top: 10px; font-size: 24px">
            {{ $t("index.screen4.title_1") }}
          </p>
          <div class="formtemp"></div>
          <div class="form">
            <div class="formmsg" style="padding-top: 30px">
              <el-row>
                <el-col :span="12">
                  <input style="width: 380px;font-size: 18px" class="fontbody" v-model="form.first_name" type="text" required
                    :placeholder="$t('index.screen4.first_name')" @blur="checkNull('first_name')" />
                  <span v-if="check.first_name" style="color: red; text-align: left">{{ $t("index.screen4.contnull")
                  }}</span>
                </el-col>
                <el-col :span="12"><input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.last_name"
                    :placeholder="$t('index.screen4.last_name')" @blur="checkNull('last_name')" />
                  <span v-if="check.last_name" style="color: red; text-align: left">{{ $t("index.screen4.contnull")
                  }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12"><input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.company"
                    :placeholder="$t('index.screen4.company')" @blur="checkNull('company')" />
                  <span v-if="check.company" style="color: red; text-align: left">{{
                      $t("index.screen4.contnull")
                  }}</span>
                </el-col>
                <el-col :span="12">
                  <input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.zip" @blur="checkNull('zip')"
                    :placeholder="$t('index.screen4.zip')" />
                  <span v-if="check.zip" style="color: red; text-align: left">{{
                      $t("index.screen4.contnull")
                  }}</span>

                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="fontbody">
                  <input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.mail" @blur="checkNull('mail')"
                    :placeholder="$t('index.screen4.mail')" />
                  <span v-if="check.mail" style="color: red; text-align: left">{{
                      $t("index.screen4.contnull")
                  }}</span>

                </el-col>

                <el-col :span="12">
                  <input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.position"
                    :placeholder="$t('index.screen4.position')" />

                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12"><input style="width: 380px;font-size: 18px" class="fontbody" type="text" v-model="form.phone"
                    :placeholder="$t('index.screen4.phone')" /></el-col>
                <el-col :span="12">
                  <el-select v-model="form.service_type" @blur="checkNull('service_type')"
                    @change="checkNull('service_type')" :placeholder="$t('index.screen4.type')" :popper-append-to-body="false">
                    <el-option v-for="item in serviceTypeList" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span v-if="check.service_type" style="color: red; text-align: left">{{
                      $t("index.screen4.contnull")
                  }}</span>
                </el-col>
              </el-row>
            </div>

            <textarea style="margin-top: 50px;font-size: 18px" class="fontbody" :placeholder="$t('index.screen4.msg')"
              v-model="form.msg" ></textarea>
            <el-row class="flex items-center" style="margin-top: 10px">
              <span v-if="check.checked" style="color: red; text-align: left">{{
                  $t("index.screen4.contnull")
              }}</span>
              <el-col :span="1">
                <el-checkbox style="
                transform: scale(1.3);
                margin-bottom: 5px;
                margin-left: 2px;
              " @change="checkNull('checked')" v-model="form.checked"></el-checkbox>
              </el-col>

              <el-col :span="23">
                <p style="font-size: 20px; text-align: left">
                  {{ $t("index.screen4.checkedmsg1")
                  }}<a class="cursor-pointer" style="color: #0a0ae2" @click="showdpp">{{ $t("index.screen4.checkedmsg2")
}}</a>{{ $t("index.screen4.checkedmsg3") }}
                </p>
              </el-col>
            </el-row>

            <button class="subtn fontbody" style="margin-top: 45px; margin-bottom: 10%"
              @click="sendform">
              <strong>{{ $t("index.screen4.btn") }}</strong>
            </button>
          </div>
          <div v-if="dpp" class="dpp">
            <i class="el-icon-close cursor-pointer" @click="closedpp"></i>
            <div class="fontbody">
              <div ref="screenbox4_1" class="screenbox_1 screenbox4_1">
                <div class="lastcontent flex items-center" style="height: 100%; padding-top: 5%">
                  <div style="z-index: 9" class="form1 flex flex-col justify-center">
                    <div class="" style="
                    padding-top: 30px;
                    padding-left: 1.5%;
                    height: 600px;
                    overflow: auto;
                  ">
                      <h1 style="font-size: 30px">
                        <strong>Privacy Policy</strong>
                      </h1>
                      <br />
                      <h3>
                        Protecting your private information is our priority. This
                        Statement of Privacy applies to Cooper Li, and Zima Dynamic
                        LLC and governs data collection and usage. For the purposes
                        of this Privacy Policy, unless otherwise noted, all
                        references to Zima Dynamic LLC include zimashuttle.us. The
                        Zima Dynamic LLC application is a Website contact info
                        application. By using the Zima Dynamic LLC application, you
                        consent to the data practices described in this statement.
                      </h3>
                      <br />
                      <h1 style="font-size: 20px">
                        <strong>Collection of your Personal Information</strong>
                      </h1>
                      <h3>
                        We do not collect any personal information about you unless
                        you voluntarily provide it to us. However, you may be
                        required to provide certain personal information to us when
                        you elect to use certain products or services. These may
                        include: (a) registering for an account; (b) entering a
                        sweepstakes or contest sponsored by us or one of our
                        partners; (c) signing up for special offers from selected
                        third parties; (d) sending us an email message; (e)
                        submitting your credit card or other payment information
                        when ordering and purchasing products and services. To wit,
                        we will use your information for, but not limited to,
                        communicating with you in relation to services and/or
                        products you have requested from us. We also may gather
                        additional personal or non-personal information in the
                        future.
                      </h3>
                      <br />
                      <h1 style="font-size: 20px">
                        <strong>Sharing Information with Third Parties</strong>
                      </h1>
                      <h3>
                        Zima Dynamic LLC does not sell, rent or lease its customer
                        lists to third parties.
                      </h3>
                      <br />

                      <h3>
                        Zima Dynamic LLC may, from time to time, contact you on
                        behalf of external business partners about a particular
                        offering that may be of interest to you. In those cases,
                        your unique personally identifiable information (e-mail,
                        name, address, telephone number) is not transferred to the
                        third party. Zima Dynamic LLC may share data with trusted
                        partners to help perform statistical analysis, send you
                        email or postal mail, provide customer support, or arrange
                        for deliveries. All such third parties are prohibited from
                        using your personal information except to provide these
                        services to Zima Dynamic LLC, and they are required to
                        maintain the confidentiality of your information.
                      </h3>
                      <br />
                      <h3>
                        Zima Dynamic LLC may disclose your personal information,
                        without notice, if required to do so by law or in the good
                        faith belief that such action is necessary to: (a) conform
                        to the edicts of the law or comply with legal process served
                        on Zima Dynamic LLC or the site; (b) protect and defend the
                        rights or property of Zima Dynamic LLC; and/or (c) act under
                        exigent circumstances to protect the personal safety of
                        users of Zima Dynamic LLC, or the public.
                      </h3>
                      <br />

                      <br />
                      <h1 style="font-size: 20px">
                        <strong>Right to Deletion</strong>
                      </h1>
                      <h3>
                        Subject to certain exceptions set out below, on receipt of a
                        verifiable request from you, we will: <br />• Delete your
                        personal information from our records; and <br />• Direct
                        any service providers to delete your personal information
                        from their records.
                      </h3>
                      <br />

                      <h3>
                        Please note that we may not be able to comply with requests
                        to delete your personal information if it is necessary to:
                        <br />• Complete the transaction for which the personal
                        information was collected, fulfill the terms of a written
                        warranty or product recall conducted in accordance with
                        federal law, provide a good or service requested by you, or
                        reasonably anticipated within the context of our ongoing
                        business relationship with you, or otherwise perform a
                        contract between you and us; <br />• Detect security
                        incidents, protect against malicious, deceptive, fraudulent,
                        or illegal activity; or prosecute those responsible for that
                        activity; <br />• Debug to identify and repair errors that
                        impair existing intended functionality; <br />• Exercise
                        free speech, ensure the right of another consumer to
                        exercise his or her right of free speech, or exercise
                        another right provided for by law; <br />• Comply with the
                        California Electronic Communications Privacy Act; <br />•
                        Engage in public or peer-reviewed scientific, historical, or
                        statistical research in the public interest that adheres to
                        all other applicable ethics and privacy laws, when our
                        deletion of the information is likely to render impossible
                        or seriously impair the achievement of such research,
                        provided we have obtained your informed consent; <br />•
                        Enable solely internal uses that are reasonably aligned with
                        your expectations based on your relationship with us;
                        <br />• Comply with an existing legal obligation; or <br />•
                        Otherwise use your personal information, internally, in a
                        lawful manner that is compatible with the context in which
                        you provided the information.
                      </h3>
                      <br />

                      <h1 style="font-size: 20px">
                        <strong>Children Under Thirteen</strong>
                      </h1>
                      <h3>
                        Zima Dynamic LLC does not knowingly collect personally
                        identifiable information from children under the age of
                        thirteen. If you are under the age of thirteen, you must ask
                        your parent or guardian for permission to use this
                        application.
                      </h3>
                      <br />
                      <h1 style="font-size: 20px">
                        <strong>E-mail Communications</strong>
                      </h1>
                      <h3>
                        From time to time, Zima Dynamic LLC may contact you via
                        email for the purpose of providing announcements,
                        promotional offers, alerts, confirmations, surveys, and/or
                        other general communication. In order to improve our
                        Services, we may receive a notification when you open an
                        email from Zima Dynamic LLC or click on a link therein.
                      </h3>
                      <br />
                      <h3>
                        If you would like to stop receiving marketing or promotional
                        communications via email from Zima Dynamic LLC, you may opt
                        out of such communications by info@zimashuttle.us.
                      </h3>
                      <br />
                      <h1 style="font-size: 20px">
                        <strong>Changes to this Statement</strong>
                      </h1>
                      <h3>
                        Zima Dynamic LLC reserves the right to change this Privacy
                        Policy from time to time. We will notify you about
                        significant changes in the way we treat personal information
                        by sending a notice to the primary email address specified
                        in your account, by placing a prominent notice on our
                        application, and/or by updating any privacy information.
                        Your continued use of the application and/or Services
                        available after such modifications will constitute your: (a)
                        acknowledgment of the modified Privacy Policy; and (b)
                        agreement to abide and be bound by that Policy.
                      </h3>
                      <br />
                      <h1 style="font-size: 20px">
                        <strong>Contact Information</strong>
                      </h1>
                      <h3>
                        Zima Dynamic LLC welcomes your questions or comments
                        regarding this Statement of Privacy. If you believe that
                        Zima Dynamic LLC has not adhered to this Statement, please
                        contact Zima Dynamic LLC at:
                      </h3>
                      <br />
                      <h3>Zima Dynamic LLC</h3>
                      <h3>28 Stearns St.</h3>
                      <h3>Malden, Massachusetts 02148</h3>
                      <br />
                      <h3>Email Address:</h3>
                      <h3>info@zimashuttle.us</h3>
                      <br />

                      <h3>Telephone number:</h3>
                      <h3>+1.781.288.9735</h3>
                      <br />

                      <h3>Effective as of July 25, 2022</h3>
                    </div>
                    <button class="subtn fontbody" style="margin-top: 30px; margin-bottom: 10%"
                      @click="closedpp">
                      {{ $t("index.screen4.dpp") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="msg-right" style="font-size: 20px;margin-left: 25px">
          <img src="@/assets/img/about-us/aboutimg.png" style="object-fit:cover" alt="">
          <h2 style="margin-top: 24px;font-size:32px;font-weight:600">Greater Boston Area</h2>
          <span>Malden, MA 02148</span>
          <p style="margin-top:32px">
            <span>Email:</span><br>
            <span><a href="mailto:hello@zimashuttle.us">hello@zimashuttle.us</a></span><br>
            <span><a href="mailto:service@zimashuttle.us">service@zimashuttle.us</a></span>
          </p>
          <p style="margin-top:24px">
            <span>TEL:</span><br>
            <span>+1 (781) 288-9735</span>

          </p>



        </div>
      </div>
    </div>


    <BottomBar></BottomBar>
  </div>
</template>

<script>
import qs from "qs";

import BottomBar from "@/components/bottomBar.vue";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      screen: {
        height: "",
        width: "",
      },
      options: [
        {
          value: "Alabama",
          label: "Alabama",
        },
        {
          value: "Alaska",
          label: "Alaska",
        },
        {
          value: "Arizona",
          label: "Arizona",
        },
        {
          value: "Arkansas",
          label: "Arkansas",
        },
        {
          value: "California",
          label: "California",
        },
        {
          value: "Colorado",
          label: "Colorado",
        },
        {
          value: "Connecticut",
          label: "Connecticut",
        },
        {
          value: "Delaware",
          label: "Delaware",
        },
        {
          value: "Florida",
          label: "Florida",
        },
        {
          value: "Georgia",
          label: "Georgia",
        },
        {
          value: "Hawaii",
          label: "Hawaii",
        },
        {
          value: "Idaho",
          label: "Idaho",
        },
        {
          value: "Illinois",
          label: "Illinois",
        },
        {
          value: "Indiana",
          label: "Indiana",
        },
        {
          value: "Iowa",
          label: "Iowa",
        },
        {
          value: "Kansas",
          label: "Kansas",
        },
        {
          value: "Kentucky",
          label: "Kentucky",
        },
        {
          value: "Louisiana",
          label: "Louisiana",
        },
        {
          value: "Maine",
          label: "Maine",
        },
        {
          value: "Maryland",
          label: "Maryland",
        },
        {
          value: "Massachusetts",
          label: "Massachusetts",
        },
        {
          value: "Michigan",
          label: "Michigan",
        },
        {
          value: "Minnesota",
          label: "Minnesota",
        },
        {
          value: "Mississippi",
          label: "Mississippi",
        },
        {
          value: "Missouri",
          label: "Missouri",
        },
        {
          value: "Montana",
          label: "Montana",
        },
        {
          value: "Nebraska",
          label: "Nebraska",
        },
        {
          value: "Nevada",
          label: "Nevada",
        },
        {
          value: "New Hampshire",
          label: "New Hampshire",
        },
        {
          value: "New Jersey",
          label: "New Jersey",
        },
        {
          value: "New Mexico",
          label: "New Mexico",
        },
        {
          value: "New York",
          label: "New York",
        },
        {
          value: "North Carolina",
          label: "North Carolina",
        },
        {
          value: "North Dakota",
          label: "North Dakota",
        },
        {
          value: "Ohio",
          label: "Ohio",
        },
        {
          value: "Oklahoma",
          label: "Oklahoma",
        },
        {
          value: "Oregon",
          label: "Oregon",
        },
        {
          value: "Pennsylvania",
          label: "Pennsylvania",
        },
        {
          value: "Rhode Island",
          label: "Rhode Island",
        },
        {
          value: "South Carolina",
          label: "South Carolina",
        },
        {
          value: "South Dakota",
          label: "South Dakota",
        },
        {
          value: "Tennessee",
          label: "Tennessee",
        },
        {
          value: "Texas",
          label: "Texas",
        },
        {
          value: "Utah",
          label: "Utah",
        },
        {
          value: "Vermont",
          label: "Vermont",
        },
        {
          value: "Virginia",
          label: "Virginia",
        },
        {
          value: "Washington",
          label: "Washington",
        },
        {
          value: "Washington D.C.",
          label: "Washington D.C.",
        },
        {
          value: "West Virginia",
          label: "West Virginia",
        },
        {
          value: "Wisconsin",
          label: "Wisconsin",
        },
        {
          value: "Wyoming",
          label: "Wyoming",
        },
      ],
      serviceTypeList: [
        {
          label: 'General Question',
          value: "General Question",
        },
        {
          label: 'Maintenance and service',
          value: "Maintenance and service",
        },
        {
          label: 'PDF download',
          value: "PDF download",
        }
      ],
      value: "",
      form: {
        first_name: "",
        last_name: "",
        company: "",
        position: "",
        // state: "",
        // city: "",
        zip: "",
        phone: "",
        mail: "",
        msg: "",
        service_type: "",
        checked: false,
      },
      check: {
        first_name: false,
        last_name: false,
        company: false,
        // position: false,
        // state: false,
        // city: false,
        zip: false,
        // phone: false,
        mail: false,
        // msg: false,
        checked: false,
        service_type: false,
      },
      cansend: true,
      isgotop: false,
      isgonext: true,
      videoPlay: false,
      dpp: false,
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // this.getScreenSize();
    // window.onresize = () => {
    //   this.getScreenSize();
    // };
    window.onscroll = () => {
      // console.log(document.getElementById("app").scrollHeight)
      // console.log(window.scrollY)
      // if(document.getElementById("app").scrollHeight-window.scrollY<window.screen.height){
      //   this.isgotop=true
      // }else{
      //   this.isgotop=false
      // }
      if (window.scrollY > 110) {
        this.isgotop = true;
        this.isgonext = false;
      } else {
        this.isgotop = false;
        this.isgonext = true;
      }
    };
  },
  methods: {
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    showdpp() {
      this.dpp = true;
      if (this.IsPhone()) {
        this.$nextTick(() => {
          let node = document.getElementsByClassName("dpp")[0];
          console.dir(node);
          node.style.position = "absolute";
          node.top = 0;
        });
      }
    },
    closedpp() {
      this.dpp = false;
    },
    //校验
    checkNull(type) {
      if (type == "first_name") {
        this.form.first_name == ""
          ? (this.check.first_name = true)
          : (this.check.first_name = false);
        // this.$message.error("cantnull");
      } else if (type == "last_name") {
        this.form.last_name == ""
          ? (this.check.last_name = true)
          : (this.check.last_name = false);
      } else if (type == "company") {
        this.form.company == ""
          ? (this.check.company = true)
          : (this.check.company = false);
      } else if (type == "zip") {
        this.form.zip == ""
          ? (this.check.zip = true)
          : (this.check.zip = false);
      } else if (type == "mail") {
        this.form.mail == ""
          ? (this.check.mail = true)
          : (this.check.mail = false);
        if (
          !this.form.mail.match(
            /^[A-Za-z0-9.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          )
        ) {
          this.check.mail = true;
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.emailerror"),
            type: "error",
          });
        }
      } else if (type == "checked") {
        this.form.checked == false
          ? (this.check.checked = true)
          : (this.check.checked = false);
      } else if (type == "service_type") {
        this.form.service_type == ""
            ? (this.check.service_type = true)
            : (this.check.service_type = false);
      }
    },
    resetForm() {
      for (let k in this.form) {
        k == "checked" ? (this.form[k] = false) : (this.form[k] = "");
      }
    },
    sendform() {
      this.cansend = true;
      let flag = 0;
      for (let k in this.form) {
        this.checkNull(k);
      }
      for (let k in this.check) {
        if (this.check[k] == true) {
          flag++;
        }
      }
      if (flag == 0) {
        let params = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          company: this.form.company,
          job_title: this.form.position,
          // state: this.form.state,
          // city: this.form.city,
          zip: this.form.zip,
          phone: this.form.phone,
          email: this.form.mail,
          message: this.form.msg,
          checked: this.form.checked,
          service_type: this.form.service_type,
          language:Cookies.get("language")
        };
        for (let k in params) {
          if (k !== "message" && k !== "job_title" && k !== "phone") {
            if (params[k] === "" || params[k] === false) {
              console.log(k);
              console.log(params[k]);
              this.check[k] = true;
              this.cansend = false;
            }
          }
        }

        if (this.cansend) {
          this.$axios.post("/sendinfo", qs.stringify(params)).then((res) => {
            this.$message({
              showClose: true,
              message: this.$t("index.screen4.sendshow"),
              type: "success",
            });
            this.resetForm();
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.sendshow1"),
            type: "error",
          });
        }
      }
    },
  },
  components: { BottomBar },
};
</script>
<style lang="less" scoped>
//@import "../assets/css/iconfont/font.css";
.main-msg {
  // max-width: 1320px;
}

.msg-right {
  width: 31rem;
  padding-top: 120px;
}

.dpp {
  width: 1200px;
  height: 900px;
  position: fixed;
  top: 60%;
  background-color: #fff;
  border: 1px solid #ddd;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 10;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 111;
  }
}

.fonttitle {
  //letter-spacing: 5px;
}

.fontwe {
  //letter-spacing: 3px;
}

.fontbody {
  //letter-spacing: 2px;
}

.topbar {
  position: relative;
  width: 100%;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.topbar {
  color: white;
}

.icon {
  margin-left: 32px;
  font-size: 24px;

  i {
    margin-left: 8px;
  }

  span {
    font-size: 24px;
  }
}

.topbar-right span {
  font-size: 24px;
  margin-left: 24px;
}

.bottombarleft {
  color: #fff;
  font-size: 24px;

  span {
    margin-left: 16px;
  }
}

.bottombarright {
  margin-left: 80px;
  color: #fff;
  font-size: 24px;
  text-align: left;
}

.scbtn {
  float: right;
  padding: 4px 12px;
  background-color: #f7b32c00;
  border-radius: 7px;
  border: 2px solid #4d4d4d;
  font-size: 24px;
}

.subtn {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  padding: 4px 12px;
  border: 2px solid #666;
  border-radius: 4px;
  font-size: 20px;
  transition: all 0.3s;
}
.subtn:hover {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 18px;
  color: #f7b32c;
  border: 2px solid #f7b32c;
}

.thrbtn {
  padding: 4px 12px;
  background-color: #f7b32c;
  border-radius: 5px;
  color: #4d4d4d;
  font-size: 24px;
}

.lastcontent {
  position: relative;
  width: 100%;
  height: 920px;
  // padding: 200px 27.5%;

  overflow: hidden;

}

.form {
  width: 800px;
  z-index: 5;
  //justify-content: center;
  //border-top: 1px solid #4d4d4d;
}

.formtemp {
  width: 780px;
  margin-top: 20px;
  //justify-content: center;
  border-top: 1px solid #4d4d4d;
}

.form1 {
  width: 800px;
  margin: auto;
  justify-content: center;
  //border-top: 1px solid #4d4d4d;
}

.formmsg {
  width: 800px;
}

.formmsg input {
  //width: 380px;
  margin-top: 20px;
  color: #4d4d4d;
  border: none;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  outline: none;
}

.form Textarea {
  width: 780px;
  height: 200px;
  margin: 24px auto;
  padding: 10px;
  border: none;
  border: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 18px;
  outline: none;
}

.lineinput {
  border: none;
  outline: none;
  border-bottom: 3px solid #4d4d4d;
}

/deep/.el-checkbox__label {
  color: #4d4d4d;
  font-size: 24px;
}

.gotop {
  width: 80px;
  position: fixed;
  bottom: 8%;
  right: 10%;
  z-index: 99999;
}

.gonext {
  width: 20px;
  position: fixed;
  bottom: 1%;
  right: 50%;
  z-index: 99999;
}

/deep/.el-input__inner:hover {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner:focus {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input .el-select__caret {
  margin-top: 10px;
  font-size: 18px;
  color: #4d4d4d;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

/deep/.el-select {
  width: 95%;
  box-sizing: border-box;
}

/deep/.el-input__inner {
  margin-top: 9px;
  padding-top: 11px;
  color: #4d4d4d;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 18px;
  padding-left: 0;
  outline: none;

  &::placeholder {
    color: rgba(77, 77, 77, 0.5) !important;
    font-family: "OpenSans";
    //letter-spacing: 2px;
    font-size: 18px;
    font-weight: 100;
  }
}

/deep/.el-select-dropdown__item.selected {
  color: #ff7412;
}

/deep/.el-input--suffix:hover .el-input__inner {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;
}

/deep/.el-checkbox__inner {
  // width:28px;
  // height: 28px;
  border: 1px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__inner:hover {
  // width:28px;
  // height: 28px;
  border: 1px solid #f7b32c;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #40a0ff00;
  border-color: #4d4d4d;
}

/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #4d4d4d;
}

::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #000000;
  border-left: 0;
  border-top: 0;
  height: 0.4375rem;
  left: 0.25rem;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 0.1875rem;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}

// 设置下拉框的背景颜色及边框属性；
/deep/.el-select-dropdown {
  // 若不将下拉框的背景颜色设置为：transparent，那么做不出来半透明的效果；
  // 因为其最终的显示为：下拉框有一个背景颜色且下拉框的字体有一个背景颜色，重叠后的效果展示；
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
}

// 设置下拉框的字体属性及背景颜色；
.el-select-dropdown__item {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  text-align: left;
  font-size: 18px;
  color: #4d4d4d;
  background-color: #f9c559;
}

// 设置下拉框列表的 padding 值为：0；(即：样式调整)
/deep/.el-select-dropdown__list {}

// 设置输入框与下拉框的距离为：0; (即：样式调整)
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 7px;
}

// 将下拉框上的小箭头取消；(看着像一个箭头，其实是两个重叠在一起的箭头)
/deep/.el-popper .popper__arrow,
/deep/.el-popper .popper__arrow::after {
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
  //display: none;
}

// 设置鼠标悬停在下拉框列表的悬停色；
.el-select-dropdown__item:hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item.hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item:focus {
  background-color: #f7b32c;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(77, 77, 77, 0.5) !important;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(77, 77, 77, 0.5) !important;
}
</style>
