<template>
  <div class="fontbody">
    <div ref="screenbox" class="screenbox">
      <div class="
          content_first
          bg-top bg-cover bg-no-repeat
          z-10
          flex flex-col
          items-center
          justify-center
        ">
        <p class="z-10 fontwe"
          style="font-family:OpenSans-Light ;text-align: justify; font-size: 72px;font-weight: lighter; color: #fff">
          <span>{{ $t("index.screen1.msg1") }}</span><br />
          <span>{{ $t("index.screen1.msg2") }}</span><br />

          <button class="
              watchbtn
              fontbody
              flex flex-nowrap
              items-center
              cursor-pointer
            " @click="playmp4" style="margin-top: 50px">
            {{ $t("index.screen1.btnmsg") }}
            <!--            <i class="el-icon-caret-right text-2xl"></i>-->
            <img src="../assets/img/three1.png" style="margin-left: 9px; width: 8px" />
          </button>
          <img v-show="isgonext" class="animate-bounce gonext cursor-pointer" style="display: flex; z-index: 0"
            src="../assets/img/nextpage.png" alt="" @click="gonext" />
        </p>
        <div class="videoContainer">
          <video style="width: 100%; object-fit: cover; height: 100%" class="fullscreenVideo" id="bgVid1" playsinline=""
            autoplay muted="" loop="" preload="">
            <source src="https://www.zimashuttle.us/api/index_bk" type="video/mp4" />
          </video>
        </div>
      </div>
      <div v-if="videoPlay" class="videoPlay">
        <i class="el-icon-close cursor-pointer" style="color: #4d4d4d" @click="closemp4"></i>
        <video class="fullscreenVideo" id="bgVid" autoplay controls preload>
          <source src="https://www.zimashuttle.us/api/zimapro" type="video/mp4" />
        </video>
      </div>
    </div>
    <!-- <div ref="screenbox2" class="screenbox2">
      <div
        style="height: 500px; background-color: #fcc759"
        class="flex justify-center"
      >
        <img
          style="margin-top: 10px; margin-bottom: 10px; margin-right: 100px"
          src="../assets/img/doutub_gif-1.gif"
          alt=""
        />

        <div style="margin-top: 80px; margin-bottom: 50px; width: 475px">
          <p class="fonttitle" style="font-size: 40px; text-align: left">
            <strong>{{ $t("index.screen2.title1") }}</strong>
          </p>
          <p
            style="
              font-size: 20px;
              text-align: left;
              margin-top: 50px;
              height: 200px;
            "
          >
            {{ $t("index.screen2.msg1") }}
          </p>
          <button
            class="scbtn fontbody"
            style="font-size: 20px"
            @click="clickCase()"
          >
            <strong>{{ $t("index.screen2.btnmsg1") }}</strong>
          </button>
        </div>
      </div>
      <div
        style="height: 500px; background-color: #4d4d4d; color: #fff"
        class="flex justify-center"
      >
        <div
          style="
            margin-top: 80px;
            margin-bottom: 50px;
            width: 475px;
            margin-left: 220px;
          "
        >
          <p class="fonttitle" style="font-size: 40px; text-align: left">
            <strong>{{ $t("index.screen2.title2") }}</strong>
          </p>
          <p
            style="
              font-size: 20px;
              text-align: left;
              margin-top: 46px;
              height: 200px;
            "
          >
            {{ $t("index.screen2.msg2") }}
          </p>
          <button
            style="float: left; border-color: #fff; font-size: 20px"
            class="scbtn fontbody"
            @click="clickCase()"
          >
            <strong> {{ $t("index.screen2.btnmsg2") }} </strong>
          </button>
        </div>
        <img
          style="
            overflow: hidden;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 50px;
            transform: scale(0.9);
          "
          src="../assets/img/doutub_gif-2.gif"
          alt=""
        />
      </div>
    </div> -->
    <div class="flex justify-center mt-10">
      <div style="width: 1450px; height: 800px;padding-top: 50px;padding-right: 35px">
        <el-carousel height="700px" arrow="never">
          <el-carousel-item v-for="(item, index) in rootUrl === 'zh' ? z : e" :key="index">
            <!-- rootUrl + 'e' + item + '.png' -->
            <div class="flex justify-between">
              <img style="width: 750px; object-fit: contain" :src="item[0]" alt="" />
              <img style="width: 550px; object-fit: cover" :src="item[1]" alt="" />
            </div>

          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div ref="screenbox3" class="screenbox3 mt-20">
      <!-- <div
        style="height: 500px; background-color: #fff"
        class="flex justify-center"
      >
        <img
          style="
            transform: scale(1.1);
            margin-top: 50px;
            margin-bottom: 50px;
            margin-right: 120px;
            margin-left: 55px;
          "
          src="../assets/img/doutub_gif-3.gif"
          alt=""
        />
        <div style="width: 475px; margin-top: 80px; margin-bottom: 50px">
          <p class="fonttitle" style="font-size: 40px; text-align: left">
            <strong>{{ $t("index.screen3.title1") }}</strong>
          </p>
          <p
            style="
              font-size: 20px;
              text-align: left;
              margin-top: 46px;
              height: 200px;
            "
          >
            {{ $t("index.screen3.msg1") }}
          </p>
          <button
            class="scbtn fontbody"
            style="font-size: 20px"
            @click="clickCase()"
          >
            <strong>{{ $t("index.screen3.btnmsg1") }}</strong>
          </button>
        </div>
      </div> -->
      <div style="
          height: 800px;
          background-color: #000;
          color: #fff;
          /*padding-left: 200px;*/
        " class="flex justify-center">
        <div class="" style="
            margin-left: 170px;
            margin-top: 130px;
            margin-bottom: 100px;
            width: 650px;
            padding-left: 50px;
          ">
          <img style="width: 400px" src="../assets/img/index3-logo.png" alt="" />
          <p style="font-size: 50px; text-align: left; margin-top: 5px">
            {{ $t("index.screen3.title2") }}
          </p>
          <p style="font-family:OpenSans-Light ;font-size: 20px; text-align: left; margin-top: 80px">
            {{ $t("index.screen3.msg2") }}
          </p>
          <button style="float: left; font-size: 20px; margin-top: 80px" class="thrbtn fontbody" @click="toShuttle">
            {{ $t("index.screen3.btnmsg2") }}
          </button>
        </div>
        <img src="../assets/img/index3-2.png" alt="" style="

            object-fit: contain;

            overflow: hidden;
          " />
      </div>
    </div>

    <div ref="screenbox4" class="screenbox4">
      <!-- <div
        class="lastcontent flex items-center"
        style="height: 950px; padding-bottom: 100px"
      >
        <div
          style="z-index: 1; padding-top: 70px"
          class="form flex flex-col justify-center"
        >
          <p class="flex justify-center" style="font-size: 40px">
            <strong>{{ $t("index.screen4.title") }}</strong>
          </p>
          <div class="formmsg" style="padding-top: 30px; padding-left: 1.5%">
            <el-row>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  v-model="form.first_name"
                  type="text"
                  required
                  :placeholder="$t('index.screen4.first_name')"
                  @blur="checkNull('first_name')"
                />
                <span
                  v-if="check.first_name"
                  style="color: red; text-align: left"
                  >{{ $t("index.screen4.contnull") }}</span
                >
              </el-col>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  type="text"
                  v-model="form.last_name"
                  :placeholder="$t('index.screen4.last_name')"
                  @blur="checkNull('last_name')"
                />
                <span
                  v-if="check.last_name"
                  style="color: red; text-align: left"
                  >{{ $t("index.screen4.contnull") }}</span
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  type="text"
                  v-model="form.company"
                  :placeholder="$t('index.screen4.company')"
                  @blur="checkNull('company')"
                />
                <span
                  v-if="check.company"
                  style="color: red; text-align: left"
                  >{{ $t("index.screen4.contnull") }}</span
                >
              </el-col>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  type="text"
                  v-model="form.position"
                  :placeholder="$t('index.screen4.position')"
              /></el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="fontbody">
                <el-select
                  v-model="form.state"
                  @blur="checkNull('state')"
                  @change="checkNull('state')"
                  :placeholder="$t('index.screen4.state')"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span v-if="check.state" style="color: red; text-align: left">{{
                  $t("index.screen4.contnull")
                }}</span>
              </el-col>
              <el-col :span="6">
                <input
                  style="width: 180px"
                  class="fontbody"
                  type="text"
                  v-model="form.city"
                  @blur="checkNull('city')"
                  :placeholder="$t('index.screen4.city')"
                />
                <span v-if="check.city" style="color: red; text-align: left">{{
                  $t("index.screen4.contnull")
                }}</span>
              </el-col>
              <el-col :span="6">
                <input
                  style="width: 180px"
                  class="fontbody"
                  type="text"
                  v-model="form.zip"
                  @blur="checkNull('zip')"
                  :placeholder="$t('index.screen4.zip')"
                />
                <span v-if="check.zip" style="color: red; text-align: left">{{
                  $t("index.screen4.contnull")
                }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  type="text"
                  v-model="form.phone"
                  :placeholder="$t('index.screen4.phone')"
              /></el-col>
              <el-col :span="12"
                ><input
                  style="width: 380px"
                  class="fontbody"
                  type="text"
                  v-model="form.mail"
                  @blur="checkNull('mail')"
                  :placeholder="$t('index.screen4.mail')"
                  prop="email"
                />
                <span v-if="check.mail" style="color: red; text-align: left">{{
                  $t("index.screen4.contnull")
                }}</span>
              </el-col>
            </el-row>
          </div>

          <textarea
            style="margin-top: 50px"
            class="fontbody"
            :placeholder="$t('index.screen4.msg')"
            v-model="form.msg"
            @blur="checkNull('msg')"
          ></textarea>
          <el-row class="flex items-center" style="margin-top: 10px">
            <span v-if="check.checked" style="color: red; text-align: left">{{
              $t("index.screen4.contnull")
            }}</span>
            <el-col :span="1">
              <el-checkbox
                style="
                  transform: scale(1.3);
                  margin-left: 10px;
                  margin-bottom: 5px;
                "
                @change="checkNull('checked')"
                v-model="form.checked"
              ></el-checkbox>
            </el-col>
            <el-col :span="23">
              <p style="font-size: 20px; text-align: left; margin-left: 10px">
                {{ $t("index.screen4.checkedmsg1")
                }}<a
                  class="cursor-pointer"
                  style="color: #0a0ae2"
                  @click="showdpp"
                  >{{ $t("index.screen4.checkedmsg2") }}</a
                >{{ $t("index.screen4.checkedmsg3") }}
              </p>
            </el-col>
          </el-row>

          <button
            class="subtn fontbody"
            style="font-size: 20px; margin-top: 30px; margin-bottom: 10%"
            @click="sendform"
          >
            <strong>{{ $t("index.screen4.btn") }}</strong>
          </button>
        </div>
        <div v-if="dpp" class="dpp">
          <i class="el-icon-close cursor-pointer" @click="closedpp"></i>
          <div class="fontbody">
            <div ref="screenbox4_1" class="screenbox_1 screenbox4_1">
              <div
                class="lastcontent flex items-center"
                style="height: 100%; padding-top: 5%"
              >
                <div
                  style="z-index: 9"
                  class="form flex flex-col justify-center"
                >
                  <div
                    class=""
                    style="
                      padding-top: 30px;
                      padding-left: 1.5%;
                      height: 600px;
                      overflow: auto;
                    "
                  >
                    <h1 style="font-size: 30px">
                      <strong>Privacy Policy</strong>
                    </h1>
                    <br />
                    <h3>
                      Protecting your private information is our priority. This
                      Statement of Privacy applies to Cooper Li, and Zima
                      Dynamic LLC and governs data collection and usage. For the
                      purposes of this Privacy Policy, unless otherwise noted,
                      all references to Zima Dynamic LLC include zimashuttle.us.
                      The Zima Dynamic LLC application is a Website contact info
                      application. By using the Zima Dynamic LLC application,
                      you consent to the data practices described in this
                      statement.
                    </h3>
                    <br />
                    <h1 style="font-size: 20px">
                      <strong>Collection of your Personal Information</strong>
                    </h1>
                    <h3>
                      We do not collect any personal information about you
                      unless you voluntarily provide it to us. However, you may
                      be required to provide certain personal information to us
                      when you elect to use certain products or services. These
                      may include: (a) registering for an account; (b) entering
                      a sweepstakes or contest sponsored by us or one of our
                      partners; (c) signing up for special offers from selected
                      third parties; (d) sending us an email message; (e)
                      submitting your credit card or other payment information
                      when ordering and purchasing products and services. To
                      wit, we will use your information for, but not limited to,
                      communicating with you in relation to services and/or
                      products you have requested from us. We also may gather
                      additional personal or non-personal information in the
                      future.
                    </h3>
                    <br />
                    <h1 style="font-size: 20px">
                      <strong>Sharing Information with Third Parties</strong>
                    </h1>
                    <h3>
                      Zima Dynamic LLC does not sell, rent or lease its customer
                      lists to third parties.
                    </h3>
                    <br />

                    <h3>
                      Zima Dynamic LLC may, from time to time, contact you on
                      behalf of external business partners about a particular
                      offering that may be of interest to you. In those cases,
                      your unique personally identifiable information (e-mail,
                      name, address, telephone number) is not transferred to the
                      third party. Zima Dynamic LLC may share data with trusted
                      partners to help perform statistical analysis, send you
                      email or postal mail, provide customer support, or arrange
                      for deliveries. All such third parties are prohibited from
                      using your personal information except to provide these
                      services to Zima Dynamic LLC, and they are required to
                      maintain the confidentiality of your information.
                    </h3>
                    <br />
                    <h3>
                      Zima Dynamic LLC may disclose your personal information,
                      without notice, if required to do so by law or in the good
                      faith belief that such action is necessary to: (a) conform
                      to the edicts of the law or comply with legal process
                      served on Zima Dynamic LLC or the site; (b) protect and
                      defend the rights or property of Zima Dynamic LLC; and/or
                      (c) act under exigent circumstances to protect the
                      personal safety of users of Zima Dynamic LLC, or the
                      public.
                    </h3>
                    <br />

                    <br />
                    <h1 style="font-size: 20px">
                      <strong>Right to Deletion</strong>
                    </h1>
                    <h3>
                      Subject to certain exceptions set out below, on receipt of
                      a verifiable request from you, we will: <br />• Delete
                      your personal information from our records; and <br />•
                      Direct any service providers to delete your personal
                      information from their records.
                    </h3>
                    <br />

                    <h3>
                      Please note that we may not be able to comply with
                      requests to delete your personal information if it is
                      necessary to:
                      <br />• Complete the transaction for which the personal
                      information was collected, fulfill the terms of a written
                      warranty or product recall conducted in accordance with
                      federal law, provide a good or service requested by you,
                      or reasonably anticipated within the context of our
                      ongoing business relationship with you, or otherwise
                      perform a contract between you and us; <br />• Detect
                      security incidents, protect against malicious, deceptive,
                      fraudulent, or illegal activity; or prosecute those
                      responsible for that activity; <br />• Debug to identify
                      and repair errors that impair existing intended
                      functionality; <br />• Exercise free speech, ensure the
                      right of another consumer to exercise his or her right of
                      free speech, or exercise another right provided for by
                      law; <br />• Comply with the California Electronic
                      Communications Privacy Act; <br />• Engage in public or
                      peer-reviewed scientific, historical, or statistical
                      research in the public interest that adheres to all other
                      applicable ethics and privacy laws, when our deletion of
                      the information is likely to render impossible or
                      seriously impair the achievement of such research,
                      provided we have obtained your informed consent; <br />•
                      Enable solely internal uses that are reasonably aligned
                      with your expectations based on your relationship with us;
                      <br />• Comply with an existing legal obligation; or
                      <br />• Otherwise use your personal information,
                      internally, in a lawful manner that is compatible with the
                      context in which you provided the information.
                    </h3>
                    <br />

                    <h1 style="font-size: 20px">
                      <strong>Children Under Thirteen</strong>
                    </h1>
                    <h3>
                      Zima Dynamic LLC does not knowingly collect personally
                      identifiable information from children under the age of
                      thirteen. If you are under the age of thirteen, you must
                      ask your parent or guardian for permission to use this
                      application.
                    </h3>
                    <br />
                    <h1 style="font-size: 20px">
                      <strong>E-mail Communications</strong>
                    </h1>
                    <h3>
                      From time to time, Zima Dynamic LLC may contact you via
                      email for the purpose of providing announcements,
                      promotional offers, alerts, confirmations, surveys, and/or
                      other general communication. In order to improve our
                      Services, we may receive a notification when you open an
                      email from Zima Dynamic LLC or click on a link therein.
                    </h3>
                    <br />
                    <h3>
                      If you would like to stop receiving marketing or
                      promotional communications via email from Zima Dynamic
                      LLC, you may opt out of such communications by
                      info@zimashuttle.us.
                    </h3>
                    <br />
                    <h1 style="font-size: 20px">
                      <strong>Changes to this Statement</strong>
                    </h1>
                    <h3>
                      Zima Dynamic LLC reserves the right to change this Privacy
                      Policy from time to time. We will notify you about
                      significant changes in the way we treat personal
                      information by sending a notice to the primary email
                      address specified in your account, by placing a prominent
                      notice on our application, and/or by updating any privacy
                      information. Your continued use of the application and/or
                      Services available after such modifications will
                      constitute your: (a) acknowledgment of the modified
                      Privacy Policy; and (b) agreement to abide and be bound by
                      that Policy.
                    </h3>
                    <br />
                    <h1 style="font-size: 20px">
                      <strong>Contact Information</strong>
                    </h1>
                    <h3>
                      Zima Dynamic LLC welcomes your questions or comments
                      regarding this Statement of Privacy. If you believe that
                      Zima Dynamic LLC has not adhered to this Statement, please
                      contact Zima Dynamic LLC at:
                    </h3>
                    <br />
                    <h3>Zima Dynamic LLC</h3>
                    <h3>28 Stearns St.</h3>
                    <h3>Malden, Massachusetts 02148</h3>
                    <br />
                    <h3>Email Address:</h3>
                    <h3>info@zimashuttle.us</h3>
                    <br />

                    <h3>Telephone number:</h3>
                    <h3>+1.781.288.9735</h3>
                    <br />

                    <h3>Effective as of July 25, 2022</h3>
                  </div>
                  <button
                    class="subtn fontbody"
                    style="
                      font-size: 20px;
                      margin-top: 30px;
                      margin-bottom: 10%;
                    "
                    @click="closedpp"
                  >
                    <strong>{{ $t("index.screen4.dpp") }}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <GetInTouch></GetInTouch>
      <BottomBar @showcoo="showcooki"></BottomBar>
    </div>
    <img v-show="isgotop" class="gotop cursor-pointer" src="../assets/img/gotop.png" alt="" @click="gotop" />
  </div>
</template>

<script>
import qs from "qs";

import BottomBar from "@/components/bottomBar.vue";
import z1 from "@/assets/img/z1.png";
import z2 from "@/assets/img/z2.png";
import z3 from "@/assets/img/z3.png";
import z11 from "@/assets/img/z11.png";
import z21 from "@/assets/img/z21.png";
import z31 from "@/assets/img/z31.png";
import z12 from "@/assets/img/z12.png";
import z22 from "@/assets/img/z22.png";
import z32 from "@/assets/img/z32.png";
import e1 from "@/assets/img/e1.png";
import e2 from "@/assets/img/e2.png";
import e3 from "@/assets/img/e3.png";
import e11 from "@/assets/img/e11.png";
import e21 from "@/assets/img/e21.png";
import e31 from "@/assets/img/e31.png";
import e12 from "@/assets/img/e12.png";
import e22 from "@/assets/img/e22.png";
import e32 from "@/assets/img/e32.png";
export default {
  data() {
    return {
      z: [[z31, z12], [z21, z22], [z11, z32]],
      e: [[e11, e12], [e21, e22], [e31, e32]],
      // z1: [z11, z21, z31],
      // e1: [e11, e21, e31],
      // z2: [z12, z22, z32],
      // e2: [e12, e22, e32],

      rootUrl: "@/assets/img/",
      screen: {
        height: "",
        width: "",
      },
      options: [
        {
          value: "Alabama",
          label: "Alabama",
        },
        {
          value: "Alaska",
          label: "Alaska",
        },
        {
          value: "Arizona",
          label: "Arizona",
        },
        {
          value: "Arkansas",
          label: "Arkansas",
        },
        {
          value: "California",
          label: "California",
        },
        {
          value: "Colorado",
          label: "Colorado",
        },
        {
          value: "Connecticut",
          label: "Connecticut",
        },
        {
          value: "Delaware",
          label: "Delaware",
        },
        {
          value: "Florida",
          label: "Florida",
        },
        {
          value: "Georgia",
          label: "Georgia",
        },
        {
          value: "Hawaii",
          label: "Hawaii",
        },
        {
          value: "Idaho",
          label: "Idaho",
        },
        {
          value: "Illinois",
          label: "Illinois",
        },
        {
          value: "Indiana",
          label: "Indiana",
        },
        {
          value: "Iowa",
          label: "Iowa",
        },
        {
          value: "Kansas",
          label: "Kansas",
        },
        {
          value: "Kentucky",
          label: "Kentucky",
        },
        {
          value: "Louisiana",
          label: "Louisiana",
        },
        {
          value: "Maine",
          label: "Maine",
        },
        {
          value: "Maryland",
          label: "Maryland",
        },
        {
          value: "Massachusetts",
          label: "Massachusetts",
        },
        {
          value: "Michigan",
          label: "Michigan",
        },
        {
          value: "Minnesota",
          label: "Minnesota",
        },
        {
          value: "Mississippi",
          label: "Mississippi",
        },
        {
          value: "Missouri",
          label: "Missouri",
        },
        {
          value: "Montana",
          label: "Montana",
        },
        {
          value: "Nebraska",
          label: "Nebraska",
        },
        {
          value: "Nevada",
          label: "Nevada",
        },
        {
          value: "New Hampshire",
          label: "New Hampshire",
        },
        {
          value: "New Jersey",
          label: "New Jersey",
        },
        {
          value: "New Mexico",
          label: "New Mexico",
        },
        {
          value: "New York",
          label: "New York",
        },
        {
          value: "North Carolina",
          label: "North Carolina",
        },
        {
          value: "North Dakota",
          label: "North Dakota",
        },
        {
          value: "Ohio",
          label: "Ohio",
        },
        {
          value: "Oklahoma",
          label: "Oklahoma",
        },
        {
          value: "Oregon",
          label: "Oregon",
        },
        {
          value: "Pennsylvania",
          label: "Pennsylvania",
        },
        {
          value: "Rhode Island",
          label: "Rhode Island",
        },
        {
          value: "South Carolina",
          label: "South Carolina",
        },
        {
          value: "South Dakota",
          label: "South Dakota",
        },
        {
          value: "Tennessee",
          label: "Tennessee",
        },
        {
          value: "Texas",
          label: "Texas",
        },
        {
          value: "Utah",
          label: "Utah",
        },
        {
          value: "Vermont",
          label: "Vermont",
        },
        {
          value: "Virginia",
          label: "Virginia",
        },
        {
          value: "Washington",
          label: "Washington",
        },
        {
          value: "Washington D.C.",
          label: "Washington D.C.",
        },
        {
          value: "West Virginia",
          label: "West Virginia",
        },
        {
          value: "Wisconsin",
          label: "Wisconsin",
        },
        {
          value: "Wyoming",
          label: "Wyoming",
        },
      ],
      value: "",
      form: {
        first_name: "",
        last_name: "",
        company: "",
        position: "",
        state: "",
        city: "",
        zip: "",
        phone: "",
        mail: "",
        msg: "",
        checked: false,
      },
      check: {
        first_name: false,
        last_name: false,
        company: false,
        position: false,
        state: false,
        city: false,
        zip: false,
        phone: false,
        mail: false,
        msg: false,
        checked: false,
      },
      cansend: true,
      isgotop: false,
      isgonext: true,
      videoPlay: false,
      dpp: false,
      cooki: false,
    };
  },
  created() {

  },
  mounted() {
    if (this.$Cookies.get("language") === "zh") {
      this.rootUrl = "zh";
    }
    this.$eventBus.$on("changeLanguage", (language) => {
      this.rootUrl = language === "zh" ? "zh" : "en";
    });

    if (this.IsPhone()) {
      let c = document.getElementsByClassName("screenbox")[0];
      let v = document.getElementsByClassName("videoContainer")[0];
      c.style.height = "800px";
      console.log(v);
      console.log(c);
      v.style.height = c.style.height;
    }
    // document.getElementById("/home").style.color = "#f7b32c";
    // document.getElementById("/shuttle").style.color = "#fff";
    // document.getElementById("/insight").style.color = "#fff";
    // document.getElementById("/contact").style.color = "#fff";
    // document.getElementById("/FAQ").style.color = "#fff";
    // document.getElementById("//home").style.color = "#f7b32c";
    // document.getElementById("//shuttle").style.color = "#fff";
    // document.getElementById("//insight").style.color = "#fff";
    // document.getElementById("//contact").style.color = "#fff";
    // document.getElementById("//FAQ").style.color = "#fff";

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // window.onresize = () => {
    //   this.getScreenSize();
    // };
    window.onscroll = () => {
      // console.log(window.scrollY);
      if (window.scrollY > 100) {
        this.isgotop = true;
        this.isgonext = false;
      } else {
        this.isgotop = false;
        this.isgonext = true;
      }
    };
  },
  //    watch: {
  //     "$route.path": {
  //       immediate: true, // 第一次的数据，也要当做是一种变化
  //       deep: true,
  //       // 处理函数（不能修改名字） handler('新的值'，'旧的值')
  //       handler(new_value, old_value) {

  //         // this.scrollToTop();
  //         if(new_value=='/home')
  //         {
  //           if( this.IsPhone()){
  //             let c = document.getElementsByClassName("screenbox")[0]
  //  let v = document.getElementsByClassName("videoContainer")[0]
  //  c.style.height='800px'
  //  console.log(v);
  //  console.log(c);
  //   v.style.height=c.style.height
  //           }
  //         }
  //       },
  //     },
  //   },
  methods: {
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    toShuttle() {
      this.gotop1();
      this.$router.push({ path: "shuttle" });
      // document.getElementById("/home").style.color = "#fff";
      // document.getElementById("/shuttle").style.color = "#f7b32c";
      // document.getElementById("/insight").style.color = "#fff";
      // document.getElementById("/contact").style.color = "#fff";
      // document.getElementById("//home").style.color = "#fff";
      // document.getElementById("//shuttle").style.color = "#f7b32c";
      // document.getElementById("//insight").style.color = "#fff";
      // document.getElementById("//contact").style.color = "#fff";
    },
    clickCase() {
      this.$router.push({ path: "insight" });
      document.getElementById(this.$route.path).style.color = "#f7b32c";
      document
        .getElementsByClassName("screenbox")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    // getScreenSize() {
    //   this.screen.height = document.documentElement.clientHeight;
    //   this.screen.width = window.screen.width;
    //   let style = "1000px";
    //   let style3 = "1300px";
    //   let s = document.getElementsByClassName("screenbox");
    //   this.$refs["screenbox"].style.height = style;
    //   this.$refs["screenbox2"].style.height = style;
    //   this.$refs["screenbox3"].style.height = style3;
    //   this.$refs["screenbox4"].style.height = style;
    //   // document.getElementsByClassName('topbar')[0].style.height=
    // },
    gotop() {
      document
        .getElementsByClassName("fontbody")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    gotop1() {
      document
        .getElementsByClassName("fontbody")[0]
        .scrollIntoView({ block: "start" });
    },
    gonext() {
      document
        .getElementsByClassName("mt-10")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    goquote() {
      document
        .getElementsByClassName("screenbox4")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    playmp4() {
      this.videoPlay = true;
      if (this.IsPhone()) {
        this.$nextTick(() => {
          let node = document.getElementsByClassName("videoPlay")[0];
          console.dir(node);
          node.style.position = "absolute";
          node.top = 0;
        });
      }
      this.isgonext = false;
    },
    closemp4() {
      this.videoPlay = false;
      this.isgonext = true;
    },
    showdpp() {
      this.dpp = true;
      if (this.IsPhone()) {
        this.$nextTick(() => {
          let node = document.getElementsByClassName("dpp")[0];
          console.dir(node);
          node.style.position = "absolute";
          node.top = 0;
        });
      }
    },
    closedpp() {
      this.dpp = false;
    },
    showcooki() {
      this.cooki = true;
    },
    closecooki() {
      this.cooki = false;
    },
    //校验
    checkNull(type) {
      if (type == "first_name") {
        this.form.first_name == ""
          ? (this.check.first_name = true)
          : (this.check.first_name = false);
        // this.$message.error("cantnull");
      } else if (type == "last_name") {
        this.form.last_name == ""
          ? (this.check.last_name = true)
          : (this.check.last_name = false);
      } else if (type == "company") {
        this.form.company == ""
          ? (this.check.company = true)
          : (this.check.company = false);
      } else if (type == "state") {
        this.form.state == ""
          ? (this.check.state = true)
          : (this.check.state = false);
      } else if (type == "city") {
        this.form.city == ""
          ? (this.check.city = true)
          : (this.check.city = false);
      } else if (type == "zip") {
        this.form.zip == ""
          ? (this.check.zip = true)
          : (this.check.zip = false);
      } else if (type == "mail") {
        if (
          !this.form.mail.match(
            /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          )
        ) {
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.emailerror"),
            type: "error",
          });
        }

        this.form.mail == ""
          ? (this.check.mail = true)
          : (this.check.mail = false);
      } else if (type == "msg") {
        this.form.msg == ""
          ? (this.check.msg = true)
          : (this.check.msg = false);
      } else if (type == "checked") {
        this.form.checked == false
          ? (this.check.checked = true)
          : (this.check.checked = false);
      }
    },
    resetForm() {
      for (let k in this.form) {
        k == "checked" ? (this.form[k] = false) : (this.form[k] = "");
      }
    },
    sendform() {
      this.cansend = true;
      let params = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        company: this.form.company,
        position: this.form.position,
        state: this.form.state,
        city: this.form.city,
        zip: this.form.zip,
        phone: this.form.phone,
        mail: this.form.mail,
        msg: this.form.msg,
        checked: this.form.checked,
      };
      for (let k in params) {
        if (k !== "position" && k !== "phone") {
          if (params[k] === "" || params[k] === false) {
            console.log(k);
            console.log(params[k]);
            this.check[k] = true;
            this.cansend = false;
          }
        }
      }
      // this.$message({
      //     showClose: true,
      //     message: "提交成功！",
      //     type: "success",
      //   });
      //    this.resetForm()
      if (this.cansend) {
        this.$axios.post("/sendinfo", qs.stringify(params)).then((res) => {
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.sendshow"),
            type: "success",
          });
          this.resetForm();
        });
      } else {
        this.$message({
          showClose: true,
          message: this.$t("index.screen4.sendshow1"),
          type: "error",
        });
      }

      // this.$axios.post('/hhh',qs.stringify(params))
    },
  },
  destroyed() {
    this.$eventBus.$off("changeLanguage");
  },
  components: { BottomBar },
};
</script>
<style lang="less" scoped>
.fonttitle {
  //letter-spacing: 5px;
}

.fontwe {
  width: 1320px;
  max-width: 1320px;

}

.fontbody {
  //letter-spacing: 2px;
}

.screenbox {
  position: relative;
  width: 100%;
  height: 56vw;
  // width: 100%;
  //  height: 56vw;

  //   overflow:hidden ;
}

.screenbox2 {
  position: relative;
  width: 100%;

  // width: 100%;
  //  height: 56vw;

  //   overflow:hidden ;
}

.screenbox3 {
  position: relative;
  width: 100%;

  // width: 100%;
  //  height: 56vw;

  //   overflow:hidden ;
}

.screenbox_first {}

.topbar {
  position: relative;
  width: 100%;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.topbar {
  color: white;
}

.icon {
  margin-left: 32px;
  font-size: 24px;

  i {
    margin-left: 8px;
  }

  span {
    font-size: 24px;
  }
}

.topbar-right span {
  font-size: 24px;
  margin-left: 24px;
}

//.bottombarleft {
//  color: #fff;
//  font-size: 24px;
//  span {
//    margin-left: 16px;
//  }
//}
//.bottombarright {
//  margin-left: 80px;
//  color: #fff;
//  font-size: 24px;
//  text-align: left;
//}
.videoContainer {
  position: absolute;
  width: 100%;
  height: 56vw;
  // width: 100%;
  // height: 56vw;
  // width: 100%;
  // height: 100%;

  overflow: hidden;
  z-index: -100;
}

.dpp {
  width: 1200px;
  height: 900px;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 10;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 11;
  }
}

.videoPlay {
  width: 800px;
  height: 600px;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 10;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 11;
  }
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
}

.content_first {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content_first::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  z-index: 2;
}

.watchbtn {
  padding: 4px 12px;
  background-color: #f7b32c00;
  border-radius: 7px;
  border: 2px solid #f7b32c;
  font-size: 20px;
  transition: all 0.1s;
}
.watchbtn:hover {
  padding: 4px 12px;
  color: #f7b32c;
  border-radius: 7px;
  border: 2px solid #f7b32c;
  font-size: 20px;
}

.scbtn {
  float: right;
  padding: 4px 12px;
  background-color: #f7b32c00;
  border-radius: 7px;
  border: 2px solid #4d4d4d;
  font-size: 24px;
}

.subtn {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  margin-left: 6px;
  padding: 4px 12px;
  background-color: #f7b32c00;
  border: 2px solid #4d4d4d;
  font-size: 24px;
}

.thrbtn {
  padding: 4px 12px;
  background-color: #f7b32c;
  border-radius: 5px;
  color: #4d4d4d;
  font-size: 24px;
}
.thrbtn:hover {
  padding: 2px 10px;
  color: #000000;
  border-radius: 5px;
  border: 2px solid #f7b32c;
  font-size: 18px;
}
.screenbox>img {
  position: absolute;
  left: 480px;
  bottom: 160px;
  transform: translate(-250px, -250px);
}

.lastcontent {
  height: 800px;
  background-color: #f9c559;
  overflow: hidden;
}

.form {
  width: 800px;
  margin: auto;
  justify-content: center;
}

.formmsg input {
  //width: 380px;
  margin-top: 16px;
  color: #4d4d4d;
  border: none;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  outline: none;
}

.form Textarea {
  width: 784px;
  height: 200px;
  margin-top: 240px;
  margin: 24px auto;
  padding: 8px;
  border: none;
  border: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  outline: none;
}

.lineinput {
  border: none;
  outline: none;
  border-bottom: 3px solid #4d4d4d;
}

/deep/.el-checkbox__label {
  color: #4d4d4d;
  font-size: 24px;
}

.screenbox4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/img/lastbg.png);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: bottom 100px right 50px;
  background-origin: padding-box;
  z-index: 0;
}

.gotop {
  width: 80px;
  position: fixed;
  bottom: 8%;
  right: 10%;
  z-index: 99999;
}

.gonext {
  width: 20px;
  position: fixed;
  bottom: 1%;
  right: 50%;
  z-index: 99999;
}

/deep/.el-input__inner:hover {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner:focus {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input .el-select__caret {
  color: #4d4d4d;
  font-size: 20px;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

/deep/.el-select {
  width: 95%;
  box-sizing: border-box;
}

/deep/.el-select-dropdown__item.selected {
  color: #ff7412;
}

/deep/.el-input__inner {
  margin-top: 16px;
  color: #4d4d4d;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  padding-left: 0;
  outline: none;

  &::placeholder {
    color: rgba(77, 77, 77, 0.5) !important;
    font-family: "OpenSans";
    //letter-spacing: 2px;
    font-size: 24px;
    font-weight: 100;
  }
}

/deep/.el-input--suffix:hover .el-input__inner {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;
}

/deep/.el-checkbox__inner {
  // width:28px;
  // height: 28px;
  border: 1px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__inner:hover {
  // width:28px;
  // height: 28px;
  border: 1px solid #ffe136;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #40a0ff00;
  border-color: #4d4d4d;
}

/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #4d4d4d;
}

// 设置下拉框的背景颜色及边框属性；
/deep/.el-select-dropdown {
  // 若不将下拉框的背景颜色设置为：transparent，那么做不出来半透明的效果；
  // 因为其最终的显示为：下拉框有一个背景颜色且下拉框的字体有一个背景颜色，重叠后的效果展示；
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
}

// 设置下拉框的字体属性及背景颜色；
.el-select-dropdown__item {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  text-align: left;
  font-size: 26px;
  color: #4d4d4d;
  background-color: #f9c559;
}

// 设置下拉框列表的 padding 值为：0；(即：样式调整)
/deep/.el-select-dropdown__list {}

// 设置输入框与下拉框的距离为：0; (即：样式调整)
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 7px;
}

// 将下拉框上的小箭头取消；(看着像一个箭头，其实是两个重叠在一起的箭头)
/deep/.el-popper .popper__arrow,
/deep/.el-popper .popper__arrow::after {
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
  //display: none;
}

// 设置鼠标悬停在下拉框列表的悬停色；
.el-select-dropdown__item:hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item.hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item:focus {
  background-color: #f7b32c;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(77, 77, 77, 0.5) !important;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(77, 77, 77, 0.5) !important;
}

/deep/.el-carousel__indicators--horizontal {
  position: relative;
  left: 670px;
  //right: 10px;
  bottom: 0px;
  text-align: right;

  .el-carousel__indicator--horizontal button {
    width: 425px;
    height: 4px;
    background: #000000;
    //border-radius: 50%;
    opacity: 0.2;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 425px;
    height: 4px;
    background: #000000;
    opacity: 0.5;
    border-radius: 10%;
  }
}
</style>
