<template>
  <div id="app">
    <topBar v-if="barshow"></topBar>
    <router-view />
  </div>
</template>
<script>
import topBar from "@/components/topBar.vue";
import Cookies from "js-cookie";
export default {
  name: "app",
  components: {
    topBar,
  },
  data() {
    return {
      barshow: true,
      i: 0,
    };
  },
  // watch: {
  //   "$route.path": {
  //     immediate: true, // 第一次的数据，也要当做是一种变化
  //     deep: true,
  //     // 处理函数（不能修改名字） handler('新的值'，'旧的值')
  //     handler(new_value, old_value) {
  //       console.log('1111'+new_value);
  //       // this.scrollToTop();
  //       if(new_value=='/home')
  //       {
  //         this.isScale()
  //       }
  //     },
  //   },
  // },
  mounted() {
    console.log(this.$route);
    this.switchFont();

    if (this.IsPhone() == false) {
      window.addEventListener("scroll", () => {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let scroll = scrollTop - this.i;
        this.i = scrollTop;

        this.barshow = scroll < 0 || window.scrollY <= 110;

        // this.barshow = window.scrollY <= 110;
      });
    }
    this.isScale();
  },
  created() {
    console.log("IsPhone", this.IsPhone());
  },
  methods: {
    switchFont() {
      let currentLang = Cookies.get("language");
      if (currentLang == "zh") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "Microsoft JhengHei";
        console.log(document.body.style);
      }
      if (currentLang == "en") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "OpenSans";
        console.log(document.body.style);
      }
    },
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    test() {
      console.log("123");
    },
    isScale() {
      if (this.IsPhone()) {
        let b = document.getElementById("app");

        this.changeVideoSize();

        let mobwidth = b.clientWidth;
        console.log(window.innerWidth);
        console.log(b.clientWidth);
        console.log(window.innerHeight);
        console.log(b.clientHeight);
        b.style.width = "1920px";
        let scale = mobwidth / 1920;
        console.log(mobwidth, "1920px", scale);
        // let sx = window.innerWidth/body.clientWidth
        // let sy = window.innerHeight/body.clientHeight
        // body.style.transform='scale('+sx+','+sy+')'
        body.style.transform = "scale(" + scale + ")";
        body.style.transformOrigin = "0px 0px";
        b.style.height = (b.style.height * scale).toString();
        console.dir(b);
      }
    },
    changeVideoSize() {
      if (this.$route.path == "/home") {
        let c = document.getElementsByClassName("screenbox");
        let bar = document.getElementsByClassName("topbar")[0];
        let v = document.getElementsByClassName("videoContainer")[0];

        bar.style.width = "1920px";
        let arrayc = Array.from(c);
        arrayc.forEach((item) => {
          item.style.width = "100%";
          item.style.height = "800px";
        });
        v.style.height = c[0].style.height;
      } else {
        let bar = document.getElementsByClassName("topbar")[0];
        bar.style.width = "1920px";
      }
    },
    // //滚动到顶部
    // scrollToTop() {
    //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // },
  },
};
</script>
<style lang="less">
// #app {
//   font-family:  Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
// body {
//   font-family:  Helvetica, Arial, sans-serif !important;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
@font-face {
  font-family: 'Agency FB';
  src: url("./assets/css/iconfont/Agency-FB.ttf");
}

@font-face {
  font-family: 'OpenSans';
  src: url("./assets/font/OpenSans-Regular.ttf");
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url("./assets/font/OpenSans-Light.ttf");
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
