<template>
  <div class="mainbox fontbody">
    <div style="background-color: #000; height: 120px"></div>
    <div class="flex justify-center">
    <div class="incontainer">
      <h1 class="fontwe" style="font-size: 48px">{{ $t("faq.title") }}</h1>
      <p style="margin-top: 10px">{{ $t("faq.msg") }}</p>

      <el-collapse v-model="activeNames" style="margin-top: 20px;width: auto">
        <el-collapse-item :title="$t('faq.title1')" name="1">
          <div class="title_msg">
            <h2>{{ $t("faq.title1_1") }}</h2>
            <p>{{ $t("faq.msg1_1") }}</p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title1_2") }}</h2>
            <p>
              {{ $t("faq.msg1_2")
              }}<a href="mailto:hello@zimashuttle.us" style="color: #f7b32c"
                >hello@zimashuttle.us</a
              >
            </p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title1_3") }}</h2>
            <p>{{ $t("faq.msg1_3") }}</p>
          </div>
<!--          <div class="title_msg">-->
<!--            <h2>{{ $t("faq.title1_4") }}</h2>-->
<!--            <p>{{ $t("faq.msg1_4") }}</p>-->

        </el-collapse-item>
        <el-collapse-item :title="$t('faq.title2')" name="2">
          <template slot="title">
            <span style="color: #f7b32c;font-family: 'Agency FB';letter-spacing: 3px;font-size: 36px">{{ $t("faq.title2") }}</span><span style="font-family: 'Agency FB';font-size: 36px;letter-spacing: 3px">{{ $t("faq.title2_") }}</span>

          </template>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_1") }}</h2>
            <p>{{ $t("faq.msg2_1") }}</p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_2") }}</h2>
            <p>{{ $t("faq.msg2_2") }}</p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_3") }}</h2>
            <p>{{ $t("faq.msg2_3") }}</p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_4") }}</h2>
            <p>
              {{ $t("faq.msg2_4")
              }}<a href="mailto:hello@zimashuttle.us" style="color: #f7b32c"
                >hello@zimashuttle.us</a
              >{{ $t("faq.msg2_4_2") }}
            </p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_5") }}</h2>
            <p>{{ $t("faq.msg2_5") }}</p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title2_6") }}</h2>
            <p>{{ $t("faq.msg2_6") }}</p>
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$t('faq.title3')" name="3">
          <div class="title_msg">
            <h2>{{ $t("faq.title3_1") }}</h2>
            <p>{{ $t("faq.msg3_1") }}</p>
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$t('faq.title4')" name="4">
          <div class="title_msg">
            <h2>{{ $t("faq.title4_1") }}</h2>
            <p>
              {{ $t("faq.msg4_1")
              }}<a href="mailto:service@zimashuttle.us" style="color: #f7b32c"
                >service@zimashuttle.us</a
              >{{ $t("faq.msg4_1_1") }}
            </p>
          </div>
          <div class="title_msg">
            <h2>{{ $t("faq.title4_2") }}</h2>
            <p>{{ $t("faq.msg4_2") }}</p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    </div>
    <GetInTouch style="margin-top: 120px"></GetInTouch>
    <BottomBar class="bottom"></BottomBar>
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";
export default {
  components: { BottomBar },
  data() {
    return {
      activeNames: ["1"],
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<style lang="less" scoped>
.mainbox {
  background-color: white;
  color: #000;
}

.fonttitle {
  //letter-spacing: 5px;
}

.fonttitle1 {
  font-family: "OpenSans";
  //letter-spacing: 5px;
}

.fontwe {
  //letter-spacing: 3px;
}

.fontbody {
  //letter-spacing: 2px;
}

/deep/.el-collapse {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/deep/.el-collapse-item__content {
  background-color: #fff;
  color: #000;
  font-size: 24px;
}

/deep/.el-collapse-item__header {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 32px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

/deep/.el-collapse-item__wrap {
  background-color: white;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

/deep/.el-collapse-item__arrow {
  color: #f7b32c;
}

.incontainer {
  width: 1320px;
  padding-top: 120px;
  padding-bottom: 20px;

  // background-color: pink;
  h1 {
    font-size: 48px;
    font-weight: bold;
    text-align: left;
  }

  p {
    margin: 16px 0;
    font-size: 24px;
  }
}

.title_msg {
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px rgba(0, 0, 0, 0.2) dashed;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 200;
  }
}

.title_msg:nth-last-child(1) {
  border-bottom: none;
}
</style>
