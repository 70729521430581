module.exports = {
    language: {
        name: "English"
    },
    login: {
        userTip: "EnglishLogin",
        registerFree: "register",
        forget: "Forget Password"
    },
    topbar: {
        home: 'Home',
        shuttle: 'Shuttle',
        insight: 'Insights',
        contact: 'Contact us',
        faq: 'FAQ',
        about: 'About Us'
    },
    bottombar: {
        language: 'Language',


        home: 'Home',
        shuttle: 'Shuttle',
        insight: 'Insights',
        contact: 'Contact us',
        faq: 'FAQ',
        about: 'About Us'
    },
    index: {
        screen1: {
            msg1: 'We discover,',
            msg2: 'the future of warehouse management.',
            btnmsg: 'Watch video '
        },
        screen2: {
            title1: 'Space +',
            msg1: ' By eliminating the aisles between the racks, warehouse can increase up to 100% space. ZimaShuttle also reduces the distances between pallets in each aisle to increase the density of goods in a single-channel and improve rack space utilization.',
            btnmsg1: 'See case',
            title2: 'Safety +',
            msg2: 'The anti-collision system guarantees the safety of the goods. By reducing congestion and conflict of aisles, personal safety is guaranteed. A lighter body reduces the pressure and wear of ZimaShuttle on the rack and rail. Anti-slip surface avoids goods slipping off.',
            btnmsg2: 'See case',
        },
        screen3: {
            title1: 'Efficiency +',
            msg1: 'LIFO/FIFO mode and continuous  access mode improve the speed of loading  and unloading. Use ZimaShuttle APP on IP67 and MIL-STD-810G tablet to control up to 21 shuttles through 433 communication. ZimaShuttle suits various forklifts, and the training only takes a few hours.',
            btnmsg1: 'See case',
            title2: 'A Light, Powerful, and Intelligent system.',
            msg2: ' The 4th Generation ZimaShuttle is a pallet shuttle system suit for multiple storage scenarios and temperatures. ZimaShuttle has been in safe operation for over 300,000 hours.',
            btnmsg2: 'Explore',
        },
        screen4: {
            title: 'Contact us',
            title_1:'For more ZimaShuttle information.',
            contnull: '*',
            first_name: 'First Name*',
            last_name: 'Last Name*',
            company: 'Company*',
            position: 'Position',
            state: 'State*',
            city: 'City*',
            zip: 'Zip*',
            phone: 'Phone',
            mail: 'E-mail*',
            msg: 'Your Message',
            checkedmsg1: 'I have read and accept the terms and conditions of the ',
            checkedmsg2: 'Data Protection Policy',
            checkedmsg3: '.',
            btn: 'Send',
            sendshow: 'Sent successfully',
            sendshow1: 'Please fill in the required items',
            emailerror: 'Email format error!',
            dpp:'Close',
            type:'Service type*',
        },
    },
    shuttle: {
        introduce: {
            title1: 'For normal temperature environment',
            msg1: 'Operation temperature: 32 °F ~ 122 °F',
            msg11: 'Applied to textile, cosmetic, machinery, material handling and most logistics industry.',

            title2: 'For low temperature environment',
            msg2: 'Operation temperature: -22 °F ~ 122 °F',
            msg22: 'Applied to food, beverage, liquor, and most frozen supply chain.',

            title3: 'For high risk and sensitive environment',
            msg3: 'Operation temperature: 32 °F ~ 122 °F ',
            msg32: '2-zone explosion proof.',
            msg33: 'Applied to biological, pharmaceutical, petroleum and chemical warehouses.',

            msg4_0: 'For more technical details about the',
            msg4_1: 'system.',
            btn: 'Download Product Catalogue (PDF)',
            btn1: 'Download'
        },
        pot: {
            title: 'Main Features',
            card1: {
                title1: 'Improved lightweight body',
                msg1: ' Through its new body design, the 4th-generation ZimaShuttle reduces its weight by 440.9 pounds, effectively reducing the load-bearing pressure on warehouse racks and rails.',

                title2: 'Extended operational hours',
                msg2: "New battery technology extends the ZimaShuttle's operation time to eight to ten hours, in various operating environments, for all three models. When the battery is low, the ZimaShuttle automatically enters \"power saving mode\" to extend usage and battery life.",
            },
            card2: {
                title1: 'Variable traveling speed',
                msg1: ' Safety remains a priority across all industries. ZimaShuttle has low, medium, and high handling speeds to ensure the safety of goods in various operating environments.',

                title2: 'Increased Zima capacity',
                msg2: 'With the help of the high-powered brushless motor, the carrying capacity of the ZimaShuttle is increased up to 3417.16 pounds, effectively increasing the number of goods carried on a single pallet.',
            },
            card3: {
                title1: 'Optimized pallet spacing',
                msg1: 'ZimaShuttle can set three kinds of pallet spacing, which not only ensures the safety of goods but also improves rack storage density and maximizes space.',

                title2: 'Automatic emergency stop',
                msg2: 'ZimaShuttle can quickly and accurately check its surroundings through seven sensors located around the shuttle body; whether it encounters goods dumping or derailment, ZimaShuttle quickly and automatically stops to ensure the safety of goods and personnel.',
            },
            card4: {
                title1: 'Safe and efficient charging station',
                msg1: 'The steel charging station body firmly encloses the battery, ensuring two-way safety. All ZimaShuttle models’ batteries can be fully charged in less than five hours.',

                title2: 'Easy remote control',
                msg2: 'To adapt to various handheld scenarios, the IP67 and MIL-STD-810G protected tablet can control 21 ZimaShuttles over a long distance via 433 communication.',
            },
            card5: {
                title1: 'Anti-slip surface',
                msg1: ' The steel lift plate’s anti-slip surface ensures that goods will not slip off during the moving process. The anti-slip surface under the shuttle body is suitable for various forklifts to guarantee that the ZimaShuttle will not slip off the forklift.',

                title2: 'Statistics software',
                msg2: 'ZimaShuttle software tracks the pallet shuttle’s mileage, and the number of monthly loading and unloading to the warehouse, for optimized data analysis through both a PC and remote control.',
            },
        }

    },
    insights: {
        title: 'Insights',
        msg: "Deliver Excellent Customer Service",
        title1: "Liquor Warehouse",
        title12: "World Leading Spirit’s Company",
        title2: "Cold Storage",
        title22: "Meat Processing Plant",
        title3: "Sensitive Warehouse",
        title32: "Pharmaceutical Company",
        msg1: "Our customer is a leading traditional Chinese spirit’s company with more than 100 years of history. They are one of the largest companies in the liquor industry with 2.46 billion USD net profit in 2020 and ranked 375th in Brand Finance’s Global 500 that same year.",
        msg2: "Our customer is a sausage factory located in Guangzhou province, China. The warehouse installed refrigerating systems that maintain a temperature low -15 degrees Celsius to ensure product freshness. However, the refrigerator system's high energy consumption makes.",
        msg3: "Our customer is a pharmaceutical factory in Hebei, China, that produces vitamin B12 and is a long-term supplier for numerous international pharmaceutical companies, with a global market share of more than 40%. In 2020, the company had 1,500 employees with ¥1.3 billion RMB total revenue.",
        pallets: 'Pallets',
        space: 'Space Utilization',
        efficiency: 'Efficiency Improve',
        times:'TIMES'
    },
    insight1: {
        title: 'Liquor Warehouse – China’s Leading Spirit’s Company',
        background: 'Background:',
        backgroundmsg: 'Our customer is a leading traditional Chinese spirit’s company with more than 100 years of history. They are one of the largest companies in the liquor industry with 2.46 billion USD net profit in 2020 and ranked 375th in Brand Finance’s Global 500 that same year. The customer is based in southwest China and the upper Yangtze River. In 2021, they sought automatic warehouse equipment to improve their traditional warehouse management methods.',
        problems: 'Problems:',
        wastedspace: 'Wasted Space:',
        wastedspacemsg: "\"With people's income level increases, the consumption demand of high-end liquor kept increasing in the last five years, and our current storage has 3500 pallet capacity, which cannot accommodate the dramatically increasing amount of inventories. Therefore, we need to consider expanding our storage space by leasing some new warehouses, which would be a huge, fixed cost\" - Warehouse Manager, Ms. Wang",
        inefficiency: 'Inefficiency:',
        inefficiencymsg: "\"The loading tasks are time-sensitive to us; now, it takes more than 30-40 mines to load a truck, and the working time is unpredictable. Besides, because of the high volume of inventory, the current WMS system can't ensure all the workflow is just in order. The space between each stock set by the forklift is unstandardized, which is also a cause of space and time waste.\" - Warehouse Manager, Ms. Wang",
        safetyhazards: 'Safety Hazards:',
        safetyhazardsmsg: " \"We needed to drive into the pallet racking aisles to place or take the inventory. It is difficult to observe the full conditions on the top racks. Suppose I lift a pallet with signs of oblique, which potentially can cause the inventory to fall off the rack. Besides, during peak season, a collision and blockage is inevitable when other forklift drivers and I manually control the machine. Personal safety and inventory safety always concerns me when my head hits the pillow at night.\" - Forklift Operator, Mr. Zhao",
        say: "\"We used the ZimaShuttle for two years, and in summary, I will say the performance of the ZimaShuttle is just perfect. We admire the flexibility, efficiency, and reliability it brings to our storage system and the cost reduction compared with alternative plans. I am delighted with this smart investment.\"",
        author: "- Ms. Wang",
        result_1: "Results and output after adopting ",
        result_2: ":",
        result1: "Improved density instead of leasing new storage:",
        result2: "Efficiency dramatically increased:",
        result3: "Safety Ensured:",
        resultmsg1: "The new ZimaShuttle system allows the warehouse to store over 40 pallets in a single channel with a predetermined, standardized space between pallets.Capacity was increased by more than three times within the current space.The warehouse now holds a 10,000-pallet capacity.",
        resultmsg2: "The average time to load a truck decreased to 20 minutes, and with ZimaShuttle’s faster, streamlined process, loading times are more stable. Workers can now easily track inventory and are less likely to misplace stock like before.",
        resultmsg3: "While using the ZimaShuttle, drivers need only to place or pick up the inventory at the end of the rack and the pallet shuttle does the rest, from getting the goods to the back or shipping them to any assigned location on the rack efficiently. In addition, the pallet shuttle has installed sensors, which maintain a safe distance between inventories, and when they detect obstacles on racks, an alarm sounds. As a result, the collision between pallets and products are avoided, and increased storage improves labor and inventory safety levels.",
    },
    // insight2: {
    //     title: 'Cold Storage - Sausage Factory',
    //     background: 'Background:',
    //     backgroundmsg: 'Our customer is a sausage factory located in Guangzhou province, China. The warehouse installed refrigerating systems that maintain a temperature below -15 degrees Celsius to ensure product freshness. However, the refrigerator system’s high energy consumption makes cold storage costs much higher than ordinary warehouses. Unlike regular temperature warehouses, the location selection for cold storage is rigorous and to keep the product fresh, the warehouse must also have access to immediate transportation. The warehouse must be located upstream from the industrial district to avoid contamination, making solid storage more expensive and accounting for a 35% loss in total revenue.',
    //     problems: 'Problems:',
    //     problemsmsg: 'The emergent problem faced by our customer is lack of space.“Our storage cannot accommodate more stock for next year. But our profitability is insufficient to cover the rent of a new warehouse” – Storage manager, Mr.Jin.',
    //     solution:'Solution:',
    //     solutionmsg:'Our customer needed a flexible solution to improve storage capacity and increase productivity. In 2019, we released the second-generation ZimaShuttle. The “cold storage version” ZimaShuttle operates reliably in sub-zero temperature environments with high efficiency. Furthermore, we converted 50% of their current storage racks to a high-density rack system. After they have increased demand, we will convert the remainder of their traditional system to the high-density, semi-automatic rack system.',
    //     say: "\"We used the ZimaShuttle for two years, and in summary, I will say the performance of the ZimaShuttle is just perfect. We admire the flexibility, efficiency, and reliability it brings to our storage system and the cost reduction compared with alternative plans. I am delighted with this smart investment.\" ",


    // },
    insight2: {
        title: 'Cold Storage - Food Factory',
        background: 'Background:',
        backgroundmsg: 'Our customer is a sausage factory located in Guangzhou province, China. The warehouse installed refrigerating systems that maintain a temperature below -15 degrees Celsius to ensure product freshness. However, the refrigerator system’s high energy consumption makes cold storage costs much higher than ordinary warehouses. Unlike regular temperature warehouses, the location selection for cold storage is rigorous and to keep the product fresh, the warehouse must also have access to immediate transportation. The warehouse must be located upstream from the industrial district to avoid contamination, making solid storage more expensive and accounting for a 35% loss in total revenue.',
        problems: 'Problems:',
        problemsmsg1: 'The emergent problem faced by our customer is lack of space.',
        problemsmsg2: '\"Our storage cannot accommodate more stock for next year. But our profitability is insufficient to cover the rent of a new warehouse\" – Storage manager, Mr.Jin.',
        solution: 'Our Solution:',
        solutionmsg: 'Our customer needed a flexible solution to improve storage capacity and increase productivity. In 2019, we released the second-generation ZimaShuttle. The \"cold storage version\" ZimaShuttle operates reliably in sub-zero temperature environments with high efficiency. Furthermore, we converted 50% of their current storage racks to a high-density rack system. After they have increased demand, we will convert the remainder of their traditional system to the high-density, semi-automatic rack system.',
        say: "\"ZimaShuttle is very flexible and reliable, and it offers a much better choice than renting new cold storage. The space issue goes away because the forklift doesn’t need to drive into the channel, and the handling capacity is also significantly increased. We will convert the remaining 50% of space with ZimaShuttle before we rent new storage space. And we are certain that we need this robot in all our future warehouses.\"",
        author: "- Purchasing Supervisor, Mrs. Yan",
        result: "Results:",
        resultmsg: "After the ZimaShuttle system’s application, our racks filled 50% of the warehouse aisles and eliminated the need for forklifts. Forklift operators simply retrieve and place the goods at the end of racks. With this flexible conversion, warehouse capacity increased by 50%, aisle traffic reduced, and we can further accommodate up to 100% in the future. The Zima system helped the client save costs on renting new cold storage facilities and the client's ROI is about one year.",

    },
    insight3: {
        title: 'Sensitive Warehouse - Pharmaceutical Company',
        background: 'Background:',
        backgroundmsg: 'Our customer is a pharmaceutical factory in Hebei, China, that produces vitamin B12 and is a long-term supplier for numerous international pharmaceutical companies, with a global market share of more than 40%. In 2020, the company had 1,500 employees with ¥1.3 billion RMB total revenue. The pharmaceutical industry has rigorous standards and safety protocols for warehouse management and procurement. However, explosive, or flammable organic solvents or particles present an inevitable safety hazard for pharmaceutical companies during production, processing, transportation, and storage. Pharmaceutical companies must observe safety awareness standards and implement warehouse management measures crucial to the selection of explosion-proof equipment.',
        problems: 'Problems:',
        problemsmsg: 'Mr. Huang, the warehouse manager, stated that unsafe worker behavior caused more than 90% of workplace accidents. Since 2007, the government has added more personnel reviews in their annual safety inspection reports but, from 2008 to 2018, the company’s warehouse staff’s turnover rate remained at a high 80%. Training high-quality pharmaceutical personnel and managers requires more time and money than ever before. Mr. Huang specified that the complex warehouse safety assessment discourages young people, who, according to him, prefer a safe and stable office environment. Transforming automation for pharmaceutical companies is an industry necessity.',

        solution: 'Our Solution:',
        solutionmsg1: 'As the first company on the market to offer explosion-proof shuttles, ZimaShuttle provides the safest storage solution for all companies. Like this customer, all that is required for a single-story warehouse to become an explosion-proof structure is firewalls.',
        solutionmsg2: 'Preventing explosions or fires is an essential requirement for “high-risk” warehouses. The ZimaShuttle’s sensors and batteries are all treated to become explosion-proof according to Atex-Zone 2 standards. The automatic emergency “stop and pallet” spacing functions prevent fire and explosion caused by collision and friction of goods.',
        solutionmsg3: 'To avoid poor ventilation caused by the dense accumulation of goods, we recommend our customers use floor-stand racks instead of multi-layer racks. The ZimaShuttle offers customers its “last in, first out” mode in which incoming and outgoing stocks can be controlled while also avoiding congestion by warehouse workers in one aisle.',
        say: "\"We have tendered many warehouse shuttles, and the ZimaShuttle system perfectly meets the explosion-proof safety index of our warehouse. Our workers don't need to go into the warehouse directly, decreasing their fears and turnover. Zima has annual maintenance and will carefully check each part of the ZimaShuttle, and we are reassured to leave warehouse safety and optimization in their hands.\"",
        author: "- Manager Huang",
        result: "Results:",
        resultmsg: "Our customer successfully transitioned to a semi-automatic warehouse and passed the local annual safety inspection after installation. The ZimaShuttle System has been running safely with them since 2020.",
    },
    faq: {
        title: 'Frequently asked questions',
        msg:'Contact us for more information',


        title1: 'General',
        title1_1: 'Is the ZimaShuttle system applicable to our industry?',
        msg1_1: 'Any industry can apply the ZimaShuttle system in their warehouse, and our product applies to sectors like food, beverage & liquor industry, Frozen storage supply chain, biological & pharmaceutical industry, petroleum & chemical industry, international trade & logistics industry and more.',
        title1_2: 'What is the delivery time？',
        msg1_2: 'Normally it takes 10-15 weeks for ZimaShuttle-NT to assemble and deliver to customers. For other types of ZimaShuttle and customized products, please get in touch with us for more details at ',
        title1_3: 'What is the ROI?',
        msg1_3: 'It depends on the current warehouse volume and how our customers use their forklifts. But generally, the ROI is about one year.',
        title1_4: 'Where did ZimaShuttle produce？',
        msg1_4: 'Our product is produced and assembled in China. Our producer is a fast-growing technology company focusing on developing automatic storage equipment over decades with an independent professional technology R&D team and patents.',

        title2: 'Zima',
        title2_: 'Shuttle',
        title2_1: 'What kind of pallet types suit the ZimaShuttle?',
        msg2_1: 'Our standard products work with 48 x 40 inch Wood, Steel and Plastic pallets, but we can customize different product sizes based on our customers’ needs.',
        title2_2: 'How does the ZimaShuttle work?',
        msg2_2: 'The forklift carries the ZimaShuttle as a pallet to the channels on the rack, and then the ZimaShuttle can be remotely controlled by IP67 and MIL-STD-810G grade tablet to work manually or automatically depending on the mode selected.',
        title2_3: 'Does one ZimaShuttle only work for one channel?',
        msg2_3: 'No, the ZimaShuttle can flexibly move by forklift to different channels.',
        title2_4: 'How many ZimaShuttles do I need?',
        msg2_4: 'It depends on the volume of your warehouse and the circulation of goods. Please get in touch with us for more details: ',
        msg2_4_1: 'hello@zimashuttle.us',
        msg2_4_2: '.',
        title2_5: 'What is the suitable working environment for the ZimaShuttle?',
        msg2_5: 'ZimaShuttle suits a high-density storage system. Our ZimaShuttle NT and EP can work under 32 °F~122 °F (0℃～50℃). The ZimaShuttle LT can work under -22 °F ~122 °F (-30℃～50℃).',
        title2_6: 'What is the battery operating time and battery life?',
        msg2_6: 'Full battery charge can support ZimaShuttle to work around 8-10 hrs. One thousand cycles of residual battery capacity will be greater than 70%.',

        title3: 'Racking Structure',
        title3_1: 'Are there any requirements on pallet racks if I apply the ZimaShuttle System to my current warehouse?',
        msg3_1: 'ZimaShuttle is suitable for internationally standardized pallet racks. As long as the rack specifications meet, customers only need to build rails in the racks to use our products. If it turns out customers need new pallet racks, our technicians will connect with the racking company throughout the entire process to ensure that the customer can use the ZimaShuttle.',


        title4: 'Maintenance',
        title4_1: 'What service will you provide after purchasing the product?',
        msg4_1: 'When the product is delivered, we will have technicians to help customers to test the product and provide free training. Within one year of product delivery, we guarantee a free door-to-door maintenance of the product and free replacement of vulnerable parts. One year after product delivery, we strongly recommend customers to join our five-year warranty plan, please contact ',
        msg4_1_1: ' for details.',
        title4_2: 'Do you offer spare parts when we need to repair the shuttle?',
        msg4_2: 'Yes, once we receive your report, we will conduct a remote diagnosis and make sure you get the right part.',


    },
    aboutus:{
        title:'About Us',
        msg1:'Zima Dynamic provides automation solutions for supply chain warehouses. We help supply chain warehouses improve space utilization, reduce costs and improve logistics efficiency for sustainable business development. We specialize in designing, developing, and producing automation equipment and software for supply chain warehouses.',
        msg2:'Our main product line is ZimaShuttle and PLC. ZimaShuttle has been operating safely and stably for over four years in various warehouses, including normal-temperature, low-temperature, and sensitive environments.',
        year:' years',
        hour:' hours',
        year_msg1:'Wireless Communication R&D Experience',
        year_msg2:'Electronic and Mechanical R&D Experience',
        year_msg3:'Logistics Automatic Warehouse Equipment Experience',
        year_msg4:'ZimaShuttle Cumulative Safe Operating Hours',
        mis_title:'Our Mission',
        mis_msg:'“To accelerate the growth of sustainable manufacturing by driving the transition from automation to intelligence in supply chain warehouses.”'
    },
    touch:{
        title:'Get In Touch',
        msg:'Keep innovating and stay in awe.',
        button:'Contact Us'
    }

}
