<template>
  <div>
    <div style="background-color: #000; height: 120px"></div>
    <div class="flex justify-center">
      <div class="center-box">
        <div class="flex" style="">
          <div class="mr-4" style="width: 550px;margin-right: 50px">
            <h1 style="font-size: 48px"><strong>{{ $t("aboutus.title") }}</strong></h1>
            <p style="margin-top: 32px;font-size: 18px">
              {{ $t("aboutus.msg1") }}
            </p>
            <p style="margin-top: 32px;font-size: 18px">
              {{ $t("aboutus.msg2") }}
            </p>
          </div>
          <img
            class="ml-4"
            src="@/assets/img/about-us/bg.jpg"
            style="width: 680px; object-fit: cover;margin-left: 50px"
            alt=""
          />
        </div>
        <div
          class="mt-10 flex flex-wrap"
          style="padding: 0 0 60px 0; background-color: #f9c559;margin-top: 120px"
        >
          <div class="msg-card">
            <span style="font-size: 48px"
              >10<sup>+</sup><span style="font-size: 20px">  {{ $t("aboutus.year") }}</span></span
            ><br />
            <span style="line-height: 48px;font-size: 18px"
              >{{ $t("aboutus.year_msg1") }}</span
            >
          </div>
          <div class="msg-card">
            <span style="font-size: 48px"
              >8<sup>+</sup><span style="font-size: 20px">  {{ $t("aboutus.year") }}</span></span
            ><br />
            <span style="line-height: 48px;font-size: 18px"
              >{{ $t("aboutus.year_msg2") }}</span
            >
          </div>
          <div class="msg-card">
            <span style="font-size: 48px"
              >6<sup>+</sup><span style="font-size: 20px">  {{ $t("aboutus.year") }}</span></span
            ><br />
            <span style="line-height: 48px;font-size: 18px"
              >{{ $t("aboutus.year_msg3") }}</span
            >
          </div>
          <div class="msg-card">
            <span style="font-size: 48px"
            ><span style="font-size: 48px" id="hours_acct"></span><span style="font-size: 20px">  {{ $t("aboutus.hour") }}</span></span
            ><br />
            <span style="line-height: 48px;font-size: 18px"
              >{{ $t("aboutus.year_msg4") }}</span
            >
          </div>
        </div>
        <div class="text-center mt-20" style="margin-top: 90px">
          <h2 style="font-size: 48px">{{ $t("aboutus.mis_title") }}</h2>
          <p
            class="mt-8"
            style="
              padding: 0 4rem;
              font-size: 32px;
              font-style: italic;
              font-weight: 100;
            "
          >
            {{ $t("aboutus.mis_msg") }}
          </p>
        </div>
      </div>
    </div>
    <GetInTouch style="margin-top: 120px"></GetInTouch>
    <BottomBar></BottomBar>
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";
import GetInTouch from "@/components/GetInTouch.vue";
export default {
  components: { BottomBar, GetInTouch },
  data() {
    return {
      allhours: 0
    }
  },
  mounted() {
    let time = new Date();
    let dateBegin = new Date('2019/1/1 00:00:00');//将-转化为/，使用new Date
    let dateEnd = new Date();//获取当前时间
    let dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
    let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
    let leave1=dateDiff%(24*3600*1000)    //计算天数后剩余的毫秒数
    let hours=Math.floor(leave1/(3600*1000))//计算出小时数

    if(hours>=10){
      this.allhours = (dayDiff+1)*200+3000;
    }else {
      this.allhours = dayDiff*200+hours*20+3000;
    }

    // console.log(this.allhours);

    document.getElementById("hours_acct").innerHTML= Number(this.allhours).toLocaleString();
  },
  methods:{

  }
};
</script>

<style lang="less" scoped>
.center-box {
  width: 1320px;
  max-width: 1320px;
  margin-top: 120px;
}

.msg-card {
  width: 660px;
  margin-top: 60px;
  text-align: center;
}

h2 {
  font-size: 32px;
  font-weight: 600;
}
sup {
  display: inline-block;
  transform: translateY(30px);
}
</style>
