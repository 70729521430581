<template>
  <div>
    <div style="background-color: #000; height: 120px"></div>
    <div class="flex justify-center">
      <div class="incontainer fontbody">

        <h1 class="fontwe" style="padding-left: 50px;font-size: 48px"><strong>{{ $t("insights.title") }}</strong></h1>
        <p style="padding-left: 50px;margin-top: 32px;font-size: 48px;font-weight: lighter">{{ $t("insights.msg") }}</p>
        <div class="msgcontent flex" style="margin-top: 120px">
          <div style="">
            <h2 class="cursor-pointer" style="font-size: 48px" @click="toinsight('insight_1')"><a><strong>{{ $t("insights.title1")
            }}</strong></a></h2>
            <h2 class="" style="margin-top: 24px">{{ $t("insights.title12")
              }}</h2>
            <div class="flex">
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">15,000<sup>+</sup></span><br>
                <span style="font-size:14px">{{ $t("insights.pallets") }}</span>
              </div>
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">150%</span><br>
                <span style="font-size:14px">{{ $t("insights.space") }}</span>
              </div>
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">2 {{ $t("insights.times") }}</span><br>
                <span style="font-size:14px">{{ $t("insights.efficiency") }}</span>

              </div>
            </div>

            <p style="padding-top: 45px;padding-right: 60px;font-size: 18px;line-height: 42px">{{ $t("insights.msg1") }}</p>
          </div>
          <img style=" ;height: auto;object-fit: cover;width: 50%" class="cursor-pointer"
               src="../assets/img/Insights2/60ppi/insights3.png" alt="" @click="toinsight('insight_1')">

        </div>
        <div class="msgcontent flex" style="">
          <div>


            <h2 class="cursor-pointer" style="font-size: 48px" @click="toinsight('insight_2')"><a><strong>{{ $t("insights.title2") }}</strong></a></h2>
            <h2 class="" style="margin-top: 24px">{{ $t("insights.title22") }}</h2>
            <div class="flex">
              <div style="padding-top:45px;margin-right: 126px;">
                <span style="font-size:32px">2000<sup>+</sup></span><br>
                <span style="font-size:14px">{{ $t("insights.pallets") }}</span>
              </div>
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">200%</span><br>
                <span style="font-size:14px">{{ $t("insights.space") }}</span>
              </div>
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">4 {{ $t("insights.times") }}</span><br>
                <span style="font-size:14px">{{ $t("insights.efficiency") }}</span>

              </div>
            </div>

            <p style="padding-top: 45px;padding-right: 60px;font-size: 18px;line-height: 42px">{{ $t("insights.msg2") }}</p>

          </div>
          <img style="width: 50% ;height: auto;object-fit: cover;" class="cursor-pointer"
               src="../assets/img/Insights2/60ppi/insights2.png" alt="" @click="toinsight('insight_2')">

        </div>
        <div class="msgcontent flex" style="">
          <div>


            <h2 class="cursor-pointer" style="font-size: 48px" @click="toinsight('insight_3')"><a><strong>{{ $t("insights.title3") }}</strong></a></h2>
            <h2 class=" " style="margin-top: 24px">{{ $t("insights.title32") }}</h2>
            <div class="flex">
              <div style="padding-top:45px;margin-right: 145px;">
                <span style="font-size:32px">500<sup>+</sup></span><br>
                <span style="font-size:14px">{{ $t("insights.pallets") }}</span>
              </div>
              <div style="padding-top:45px;margin-right: 100px;">
                <span style="font-size:32px">50%</span><br>
                <span style="font-size:14px">{{ $t("insights.space") }}</span>
              </div>
              <div style="padding-top:45px;">
                <span style="font-size:32px">8 {{ $t("insights.times") }}</span><br>
                <span style="font-size:14px">{{ $t("insights.efficiency") }}</span>

              </div>
            </div>

            <p style="padding-top: 45px;padding-right: 60px;font-size: 18px;line-height: 42px">{{ $t("insights.msg3") }}</p>

          </div>
          <img style=" width: 50% ;height: auto;object-fit: cover;" class="cursor-pointer institle"
               src="../assets/img/Insights2/60ppi/insights1.png" alt="" @click="toinsight('insight_3')">

        </div>
      </div>
    </div>

    <GetInTouch style="margin-top: 120px"></GetInTouch>
    <BottomBar class="fontbody"></BottomBar>
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";
export default {
  components: { BottomBar },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  },
  methods: {
    toinsight(whichsight) {
      this.gotop1();
      this.$router.push({ path: whichsight });
      document.getElementById("/home").style.color = "#fff";
      document.getElementById("/shuttle").style.color = "#fff";
      document.getElementById("/insight").style.color = "#f7b32c";
      document.getElementById("/contact").style.color = "#fff";
      document.getElementById("//home").style.color = "#fff";
      document.getElementById("//shuttle").style.color = "#fff";
      document.getElementById("//insight").style.color = "#f7b32c";
      document.getElementById("//contact").style.color = "#fff";
    },
    gotop1() {
      document
        .getElementsByClassName("fontbody")[0]
        .scrollIntoView({ block: "start" });
    },
  },
};
</script>

<style lang="less" scoped>
.fonttitle {
  //letter-spacing: 0px;
}

.fonttitle1 {
  font-family: "OpenSans";
  //letter-spacing: 0px;
}

.fontwe {
  //letter-spacing: 0px;
}

.fontbody {
  //letter-spacing: 0px;
}

.incontainer {
  height: 100%;
  padding: 120px 0;
  background-color: #ffffff;
  width: 1410px;
  max-width: 1410px;

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
  }
}

.msgcontent {
  padding: 50px;

  //margin: 20px 0;
  //padding: 20px 0;

  &:nth-child(2n-1) {
    background-color: #f2f2f2;
  }

  > div {
    width: 800px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 20px;
  }

  img {
    width: 480px;
  }
}

.insight_bottom {
  height: 160px;
  display: flex;
}
sup {
  display: inline-block;
  transform: translateY(20px);
}
.insight_bottombox {
  line-height: 160px;
  font-size: 32px;

  &:nth-child(1) {
    width: 40%;
    padding-right: 20px;
    background-color: rgb(254, 213, 0);
    color: white;
    text-align: right;
  }

  &:nth-child(2) {
    width: 20%;
    background-color: rgb(1, 91, 187);
    color: white;
    text-align: center;
  }

  &:nth-child(3) {
    display: flex;
    padding-left: 20px;
    width: 40%;
    background-color: rgb(77, 77, 77);
    color: white;
    text-align: left;

    img {
      margin-left: 40px;
      height: 160px;
      object-fit: contain;
    }
  }
}
</style>
