<template>
  <div class="fontbody">
    <div style="background-color: #000; height: 125px"></div>
    <div class="incontainer">
      <h1 style="text-align: center; font-size: 60px">
        <strong>{{ $t("insight2.title") }}</strong>
      </h1>
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight2.background") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        {{ $t("insight2.backgroundmsg") }}
      </p>
      <img
        style="padding-top: 60px"
        src="../../assets/img/insights/insights_21.png"
        alt=""
      />
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight2.problems") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        {{ $t("insight2.problemsmsg1") }}{{ $t("insight2.problemsmsg2") }}
      </p>
      <!--      <p style="padding-top: 30px;font-size: 20px">{{ $t("insight2.problemsmsg2") }}</p>-->
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight2.solution") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        {{ $t("insight2.solutionmsg") }}
      </p>
      <video
        style="padding-top: 60px; clear: both; display: block; margin: auto"
        controls
        preload
      >
        <source src="../../assets/img/insights/insights_22.mp4" />
      </video>
      <p style="text-align: center; padding-top: 60px">
        <strong>{{ $t("insight2.say") }}</strong>
      </p>
      <p style="text-align: center">
        <strong>{{ $t("insight2.author") }}</strong>
      </p>
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight2.result") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        {{ $t("insight2.resultmsg") }}
      </p>
    </div>
    <GetInTouch style="margin-top: 120px"></GetInTouch>
    <BottomBar></BottomBar>
    <!-- >>>>>>> 9109c9437f0733ae06ac7d9e0b30e19c8107c91d -->
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";

export default {
  components: { BottomBar },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.getElementById("/home").style.color = "#fff";
    document.getElementById("/shuttle").style.color = "#fff";
    document.getElementById("/insight").style.color = "#f7b32c";
    document.getElementById("/contact").style.color = "#fff";
    document.getElementById("//home").style.color = "#fff";
    document.getElementById("//shuttle").style.color = "#fff";
    document.getElementById("//insight").style.color = "#f7b32c";
    document.getElementById("//contact").style.color = "#fff";
  },
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 28px;
  font-weight: bold;
}

.incontainer {
  padding: 75px 30% 100px;
  background-color: white;

  h1 {
    font-size: 40px;
  }

  p {
    padding: 10px 2%;
    font-size: 24px;
  }

  img {
    width: 1200px;
    // >>>>>>> 9109c9437f0733ae06ac7d9e0b30e19c8107c91d
    object-fit: contain;
  }
}

.fonttitle {
  //letter-spacing: 5px;
}

.fonttitle1 {
  font-family: "OpenSans";
  //letter-spacing: 5px;
}

.fontwe {
  //letter-spacing: 3px;
}

.fontbody {
  font-family: "OpenSans";
  //letter-spacing: 2px;
}
</style>
