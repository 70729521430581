import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";
import VueI18n from "vue-i18n";
import "./assets/css/styles.css";
import ElementUI from "element-ui"; //element-ui的全部组件
import "element-ui/lib/theme-chalk/index.css"; //element-ui的css
import "./assets/css/iconfont/iconfont.css";
import i18n from "./language";
import Cookies from "js-cookie";

import GetInTouch from "@/components/GetInTouch.vue";
// import "amfe-flexible"
Vue.config.productionTip = false;
console.log(window.navigator.language);
axios.defaults.baseURL = "/api";
Vue.prototype.$axios = axios;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$eventBus = new Vue()
Vue.use(ElementUI); //使用elementUI
Vue.use(VueI18n);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
Vue.component("GetInTouch", GetInTouch);

