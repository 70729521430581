<template>

    <div class="touch flex flex-col items-center relative">
        <h1 style="font-size:48px;font-weight:bold;">{{ $t("touch.title") }}</h1>
        <p class="mt-10" style="font-size: 24px">
          {{ $t("touch.msg") }}
        </p>
        <div class="touch-btn" @click="$router.push('contact')" @mouseenter="eggIn" @mouseleave="eggOut" style="">
<!--            <i class="el-icon-arrow-right"></i>-->
          <span>{{ $t("touch.button") }}</span>
        </div>
<!--        <img v-if="egg" ref="img" class="img" src="@/assets/img/zb.png" alt="">-->
    </div>

</template>

<script>
export default {
  name: "GetInTouch",
  data() {
    return {
      egg: false,
    };
  },
  methods: {
    eggIn(e) {
      this.egg = true;
    },
    eggOut() {
      this.egg = false;
    },
  },
};
</script>

<style lang="less" scoped>
.touch {
  height: 431px;

  background-color: #f2f2f2;
  padding: 96px 0 74px;
}

.touch-btn {
  width: 130px;
  height: 42px;
  margin-top: 55px;
  text-align: center;
  line-height: 36px;
  font-size: 20px;
  border: 2px solid #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.touch-btn:hover {
    color: #f7b32c;
    border: 2px solid #f7b32c;
    font-size: 18px;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
