module.exports = {
    language: {
        name: "中文简体"
    },
    login: {
        userTip: "请登录",
        registerFree: "免费注册",
        forget: "忘记密码"
    },
    topbar: {
        home: '主页',
        shuttle: '穿梭车',
        insight: '案例',
        contact: '联系我们',
        faq: 'FAQ',
        about: '关于我们'
    },
    bottombar: {
        language: '选择语言',


        home: '主页',
        shuttle: '穿梭车',
        insight: '案例',
        contact: '联系我们',
        faq: 'FAQ',
        about: '关于我们'

    },
    index: {
        screen1: {
            msg1: '发现，',
            msg2: '智能仓储管理的未来。',
            btnmsg: '观看视频'
        },
        screen2: {
            title1: '仓储空间 +',
            msg1: 'ZimaShuttle系统将传统货架间的间距填充为新的货架，将货架内的托盘间距大幅度减小，成倍的增加货架与托盘的数量。形成立体式，高密度的仓储空间。',
            btnmsg1: '查看案例',
            title2: '仓储安全 +',
            msg2: 'ZimaShuttle系统大幅增加仓库工作人员与货物的安全，高密度的仓储环境下的工作人员无需进入货架内部存取货物，自动急停系统与稳定运行的车身能够保障货物安全的送入通道内。',
            btnmsg2: '查看案例',
        },
        screen3: {
            title1: '仓储效率 +',
            msg1: 'ZimaShuttle系统能够自由切换LIFO与FIFO模式，配合连续出入库功能，能大幅增加仓库的存储效率。在MIL-STD-810G军工级手持平板上使用带有统计功能的定制化APP，能够同时操控21辆穿梭车。',
            btnmsg1: '查看案例',
            title2: '轻量化，高效的智能穿梭车系统。',
            msg2: '第四代ZimaShuttle系统，适应常温、低温、高危等多种工业场景下的仓储环境，保障仓储的高效运行。ZimaShuttle已经累计安全运行超过30万小时。',
            btnmsg2: '探索更多',
        },
        screen4: {
            title: '联系我们',
            title_1:'获得更多ZimaShuttle信息。',
            contnull:'×',
            first_name: '姓*',
            last_name: '名*',
            company: '公司名称*',
            position: '职位',
            state: '州*',
            city: '市*',
            zip: '邮编*',
            phone: '电话',
            mail: '邮箱地址*',
            msg: '您的留言',
            checkedmsg1: '我已阅读并接受',
            checkedmsg2: '数据保护政策',
            checkedmsg3: '的条款和条件',
            btn: '发送',
            sendshow: '发送成功！',
            sendshow1: '请填写必填项',
            emailerror: '邮箱格式错误！',
            dpp:'关闭',
            type:'服务类型*',
        },
    },
    shuttle: {
        introduce: {
            title1: '适用于常温环境',
            msg1: '工作温度: 32 °F ~ 122 °F (0 °C ~ 50 °C)',
            msg11: '适用于纺织、化妆品、机械、物料搬运和大多数物流行业。',

            title2: '适用于低温环境',
            msg2: '工作温度: -22 °F ~ 122 °F (-20 °C ~ 50 °C)',
            msg22: '适用于食品、饮料、酒类和大多数冷冻供应链。',

            title3: '适用于高危敏感环境',
            msg3: '工作温度: 32 °F ~ 122 °F (0 °C ~ 50 °C)',
            msg32: '两区隔爆防护。',
            msg33: '适用于生物、制药、石油、化工等高危敏感仓库。',

            msg4_0: '有关',
            msg4_1: '穿梭车系统的更多技术细节。',
            btn: '下载产品目录 (PDF)',
            btn1: '下载'
        },
        pot: {
            title:'主要参数',
            card1: {
                title1: '更轻的车身',
                msg1: ' 通过全新的车身设计，第四代ZimaShuttle重量减轻至440.9磅（220kg），有效降低了仓库货架和轨道的承重压力。',

                title2: '更长的运行时间',
                msg2: "新电池技术将三种型号的 ZimaShuttle 在不同工作环境中的运行时间延长至 8 到 10 小时。 当电池电量不足时，ZimaShuttle 会自动进入\"省电模式\"以延长使用时间和电池寿命。",
            },
            card2: {
                title1: '可调节的运行速度',
                msg1: ' 安全永远是所有行业的重中之重。 ZimaShuttle 可设置低、中、高三种搬运速度，以确保货物在各种作业环境中的安全。',

                title2: '更强的承载力',
                msg2: '借助大功率无刷电机，ZimaShuttle 的承载能力提升至 3417.16 磅，有效增加了单个托盘承载的货物数量。',
            },
            card3: {
                title1: '可调节托盘间距',
                msg1: 'ZimaShuttle 可以设置三种托盘间距，既保证了货物的安全，又提高了货架存储密度，最大限度地利用空间。',

                title2: '遇障自动急停',
                msg2: 'ZimaShuttle 可以通过位于车体周围的七个传感器快速准确地检查周围环境； 无论是遇到货物倾倒还是车体脱轨，ZimaShuttle 都会快速自动停车，确保货物和人员的安全。',
            },
            card4: {
                title1: '安全高效的充电站',
                msg1: '钢质充电站外壳将电池牢牢包裹，确保双向安全。 所有 ZimaShuttle 型号的电池均可在不到五个小时内充满电。',

                title2: '智能操控平板',
                msg2: '为适应各种应用场景，IP67和MIL-STD-810G军工级防护平板电脑可通过433通信，远距离同时控制多达21台ZimaShuttle。',
            },
            card5: {
                title1: '防滑表面',
                msg1: ' 拥有防滑表面的钢质升降板，能够确保货物在移动过程中不会滑落。 车体下方的防滑面则适用于各种叉车，保证 ZimaShuttle 在移库过程中不会从叉车上滑落。',

                title2: '统计功能软件',
                msg2: 'ZimaShuttle 的定制软件能够跟踪托盘穿梭车的里程，以及每月装卸到仓库的次数，能够通过 PC 和平板电脑进行优化数据分析。',
            },
        }

    },
    insights: {
        title: '客户案例',
        msg: "我们的原始客户案例",
        title1: "白酒仓库",
        title12: "世界领先的白酒酿造厂",
        title2: "冷库",
        title22: "食品加工厂",
        title3: "敏感仓库",
        title32: "制药企业",
        msg1: "我们的客户是一家具有100多年历史的领先的中国传统烈酒白酒酿造厂。他们是白酒行业最大的公司之一，2020 年净利润为 24.6 亿美元，同年在 Brand Finance 的全球 500 强中排名第 375 位。客户位于中国西南和长江上游。 2021 年，他们寻求自动化的仓储设备来改进他们传统的仓库管理方法。",
        msg2: "我们的客户是一家位于中国南部的香肠工厂。仓库温度常年需要保持在-15摄氏度以下，以确保产品新鲜。另外，与常温库不同，冷库选址严谨，为保证产品新鲜，库房还必须具备即时运输的条件。仓库还必须位于工业区的上游以避免污染，冷库系统的高能耗使得仓库成本远高于普通仓库，占到总成本的 35% 。",
        msg3: "我们的客户是中国河北的一家专门生产维生素B12的制药厂，是众多国际药品公司的长期供应商，全球市场占有率超过40%。2020年，公司员工1500人，总收入约300亿元人民币。制药行业对仓库管理和采购有严格的标准和安全协议。",
        pallets: '托盘数量',
        space: '空间优化',
        efficiency: '效率提升',
        times:'倍'
    },
    insight1: {
        title: '白酒仓库 — 中国领先的白酒酿造厂',
        background: '背景：',
        backgroundmsg: '我们的客户是一家具有100多年历史的领先的中国传统烈酒白酒酿造厂。他们是白酒行业最大的公司之一，2020 年净利润为 24.6 亿美元，同年在 Brand Finance 的全球 500 强中排名第 375 位。客户位于中国西南和长江上游。 2021 年，他们寻求自动化的仓储设备来改进他们传统的仓库管理方法。',
        problems: '问题：',
        wastedspace: '空间浪费：',
        wastedspacemsg: "\"随着人们收入水平的提高，近五年来对高端白酒的消费需求不断增加，而我们目前的仓库容量为3500托盘，无法容纳急剧增加的库存量。因此，我们需要考虑通过租用一些新的仓库来扩大我们的存储空间，这将是一笔巨大的固定成本\" - 仓库经理，王女士",
        inefficiency: '效率低下：',
        inefficiencymsg: "\"装卸任务的时间非常紧迫；现在，装载一辆卡车需要 30-40 多分钟，而且工作时间是不可预测的。此外，由于库存量大，目前的 WMS 系统不能保证所有的工作流程井井有条。叉车设置的每个库存之间的空间是不规范的，这也是空间和时间浪费的原因。\" - 仓库经理，王女士",
        safetyhazards: '安全隐患：',
        safetyhazardsmsg: " \"我们需要开车进入托盘货架通道放置或提取库存。很难观察顶部货架上的完整情况。假设我举起一个有倾斜迹象的托盘，这可能会导致库存从架子上掉下来。这可能会威胁工人的安全和损坏货物\" - 叉车操作员，赵先生",
        say: "\"我们使用 ZIMA 穿梭车两年了，总而言之，我会说 ZimaShuttle 的性能非常完美。我们钦佩它为我们的存储系统带来的灵活性、效率和可靠性，以及与替代方案相比的成本降低。我对这项明智的投资感到高兴。\" ",
        author: "- 王女士",
        result_1: "采用",
        result_2: "后的结果和成效：",
        result1: "1. 提高密度而不是租用新的仓库：",
        result2: "2. 效率显着提高：",
        result3: "3. 确保安全：",
        resultmsg1: "新的ZimaShuttle系统允许仓库在单个通道中存储 40 多个托盘，托盘之间具有预留的标准化空间。在当前仓库空间内，储存容量增加了三倍以上。该仓库现在拥有 10,000 个托盘的容量。",
        resultmsg2: "装载卡车的平均时间减少到 20 分钟，并且随着 ZimaShuttle更快、更精简的流程，装载时间更加稳定。工人现在可以轻松跟踪库存，并且不太可能像以前那样放错库存。",
        resultmsg3: "在使用 ZimaShuttle 时，司机只需在货架末端放置或提取库存，托盘穿梭车完成其余工作，无论是将货物运送到通道深处还是运送到货架上的任何指定位置都是高效的。此外，托盘穿梭车安装了多个传感器，可以保持库存之间的安全距离，当它们检测到货架上的障碍物时，会发出警报。因此，避免了托盘和产品之间的碰撞，增加的存储空间提高了劳动力和库存安全水平。",

    },
    insight2: {
        title: '冷库 – 食品加工厂',
        background: '背景：',
        backgroundmsg: '我们的客户是一家位于中国南部的香肠工厂。仓库温度常年需要保持在-15摄氏度以下，以确保产品新鲜。另外与常温库不同，冷库选址严谨，为保证产品新鲜，库房还必须具备即时运输的条件。仓库还必须位于工业区的上游以避免污染，冷库系统的高能耗使得仓库成本远高于普通仓库，占到总成本的 35% 。',
        problems: '问题：',
        problemsmsg1: '我们的客户面临的紧急问题是空间不足：',
        problemsmsg2: '\"我们的仓库无法容纳明年的更多库存。但我们的盈利能力不足以支付新仓库的租金\" – 仓储经理金先生。',
        solution: '我们的解决方案：',
        solutionmsg:'我们的客户需要一个灵活的解决方案来提高存储容量和节省成本。2019年，我们推出了第二代ZimaShuttle。 \"冷库版\" ZimaShuttle 在零下环境中可靠运行，效率更高。我们仅仅把50% 的现有存储货架架转换为高密度货架系统，即满足了客户当前的储存需求和成本控制。日后如客户储存需求再次提升，我们也能灵活地将其余传统货架部分转换为高密度、半自动货架以便搭配更多的ZimaShuttle。',
        say: "\"ZimaShuttle 非常灵活和可靠，它提供了比租用新冷库更好的选择。由于叉车不需要驶入通道，空间更大了，存取处理能力也显着提高。在我们租用新的存储空间之前，我们将使用 ZimaShuttle 直至转换剩余的 50% 空间。我们确信我们未来所有的仓库都需要这个穿梭车。\"",
        author: "- 采购主管严女士",
        result: "优化后：",
        resultmsg: "应用ZimaShuttle系统后，货架替代了50%的仓库过道，不需要更多的叉车。叉车操作员只需取回货物并将其放置在货架末端。通过这种灵活的转换，仓库容量增加了50%，过道堵塞状况减少，未来可以进一步升级为容纳 100%货物的仓库。 ZimaShuttle帮助客户节省了租用新冷库的成本，客户的投资回报率约为一年。",
    },
    insight3: {
        title: '敏感仓库 – 制药企业',
        background: '背景：',
        backgroundmsg: '我们的客户是中国河北的一家专门生产维生素B12的制药厂，是众多国际药品公司的长期供应商，全球市场占有率超过40%。2020年，公司员工1500人，总收入约300亿元人民币。制药行业对仓库管理和采购有严格的标准和安全协议。然而，易爆或易燃的有机溶剂或颗粒对制药公司在生产、加工、运输和储存过程中存在不可避免的安全隐患。制药企业必须遵守安全意识标准，实施对防爆设备选型至关重要的仓库管理措施。',
        problems: '问题：',
        problemsmsg: '仓库经理黄先生表示，90%以上的仓库工伤事故是由不安全的人为操作造成的。自 2007 年以来，政府在年度安全检查报告中增加了人员审查，但 2008 年至 2018 年，公司仓库人员离职率高达80%。培训高素质的制药人员和管理人员比以往任何时候都需要时间和预算。黄先生指出，复杂的仓库安全评估令年轻人望而却步，他认为年轻人更喜欢安全稳定的办公环境。制药公司的自动化转型是行业的必然趋势。',
        solution: '我们的解决方案：',
        solutionmsg1:'作为市场上第一家提供防爆穿梭车的公司，ZimaShuttle 为所有公司提供最安全的存储解决方案。像这位客户一样，单层仓库成为防爆结构所需要的只是防火墙。',
        solutionmsg2:'采购防止爆炸或火灾的仓库设备才是\"高风险\"仓库的重点要求。 ZimaShuttle 的传感器和电池均按照 Atex-Zone 2 标准进行了防爆处理。自动紧急停止和托盘间隔功能，防止货物碰撞和摩擦引起的火灾和爆炸。',
        solutionmsg3:'为避免货物密集堆积导致通风不良，我们建议客户使用落地式货架，而不是多层立体货架。 ZimaShuttle向客户提供\"后进先出\"模式，可以规范进出库存流程，同时避免仓库过道上的人员流通。',
        say: "\"我们已经招标了多辆仓库穿梭车，ZimaShuttle系统完全符合我们仓库的防爆安全指标。也解决了用工难，留工难的问题。每年的维护措施会保障ZimaShuttle的每个部分都得到了仔细检查，我们放心将仓库安全和优化交给他们。\"",
        author: "- 黄经理",
        result: "结果：",
        resultmsg: "我们的客户过渡到半自动仓库系统，并在安装ZimaShuttle后通过了当地的年度安全检查。自 2020 年以来，ZimaShuttle 系统一直与他们一起安全运行。",
    },
    faq: {
        title: '常见问题',
        msg:'联系我们获取更多信息',


        title1: '基础问题',
        title1_1: 'ZimaShuttle系统是否适用于我们的行业？',
        msg1_1: '任何行业的仓库都可以使用ZimaShuttle系统，我们的产品适用于食品、饮料和酒类行业、冷冻仓储供应链、生物制药行业、石油化工行业、国际贸易和物流行业等多个行业。',
        title1_2: '交货时间需要多久？',
        msg1_2: '常温版与低温版穿梭车需要 10-15 周完成组装和运送。 如需其他型号或定制的穿梭车，请联系我们了解更多详情：',
        title1_3: '产品的投资回报率是多少？',
        msg1_3: '这取决于客户当前的仓库容量以及客户如何使用他们的叉车。 根据我们的数据显示，产品投资回报率约为一年。',
        title1_4: 'ZimaShuttle 是在哪里生产的？',
        msg1_4: ' 我们的产品是在中国生产组装的。 我们的生产商是一家快速成长的科技公司，专注于开发自动化仓储设备数十年，拥有独立的专业技术研发团队和专利。',

        title2: 'Zima',
        title2_: 'Shuttle',
        title2_1: '什么样的托盘适合 ZimaShuttle？',
        msg2_1: '我们的标准产品适用于 48 x 40 英寸的木质，钢制，和塑料托盘，但我们可以根据客户的需要定制不同尺寸的穿梭车。',
        title2_2: 'ZimaShuttle 是如何工作的？',
        msg2_2: '叉车将ZimaShuttle作为托盘运送到机架上的通道，然后可以通过 IP67 和 MIL-STD-810G 军工级平板电脑远程控制ZimaShuttle手动或自动工作，具体取决于选择的模式。',
        title2_3: '一个 ZimaShuttle 是否只能用于一个通道内？',
        msg2_3: '一辆ZimaShuttle可以通过叉车灵活移动到不同的通道。',
        title2_4: '我需要多少台 ZimaShuttle？',
        msg2_4: '这取决于你的仓库的体积和货物的流通情况，请与我们联系以获取更多详细信息：',
        msg2_4_1: 'hello@zimashuttle.us',
        msg2_4_2: '。',
        title2_5: 'ZimaShuttle 适合什么样的工作环境？',
        msg2_5: 'ZimaShuttle适合高密度存储系统。常温和防爆版ZimaShuttle可在 32°F~122°F（0℃~50℃）下工作。冻库版ZimaShuttle可在 -22 °F ~122 °F (-30℃～50℃) 下工作。',
        title2_6: '电池的工作时间和电池寿命是多少？',
        msg2_6: '充满电可以支持 ZimaShuttle 工作 8-10 小时左右。 一千次循环剩余电池容量将大于70%。',

        title3: '货架结构',
        title3_1: '如果我将 ZimaShuttle System 应用到我现在的仓库，对托盘货架有什么要求吗？',
        msg3_1: 'ZimaShuttle 适用于国际标准的托盘货架。 只要机架规格符合要求，客户只需在机架上搭建导轨即可使用我们的产品。 如果客户需要新的托盘货架，我们的技术人员将全程与货架公司合作，以确保客户可以使用 ZimaShuttle。',


        title4: '售后维护',
        title4_1: '你们的产品售后服务是什么？',
        msg4_1: '当产品送达后，我们会有技术人员上门帮助客户免费调试产品并提供训练。产品交付一年以内，我们保证免费上门一次保养产品，免费更换易损零件。产品交付一年以后，我们强烈建议客户加入我们的五年保修计划，详情请咨询 ',
        msg4_1_1: '。',
        title4_2: '当我们需要维修ZimaShuttle时，厂家是否提供备件？',
        msg4_2: '一旦我们收到您的报告，我们将进行远程诊断并确保您得到正确的零件。',


    },
    aboutus:{
        title:'关于我们',
        msg1:'Zima Dynamic为供应链仓库提供自动化解决方案。我们帮助供应链仓库提高空间利用率，降低成本，提高物流效率，实现可持续的企业发展。我们专注于设计、开发和生产供应链仓库的自动化设备和软件。',
        msg2:'我们当前的主要产品是ZimaShuttle和可编程控制器。ZimaShuttle已经在多种行业的仓库中安全稳定地运行超过四年，包括常温、低温和高危敏感环境。',
        year:'年',
        hour:' 小时',
        year_msg1:'无线通信技术研发经验',
        year_msg2:'电子与机械工程研发经验',
        year_msg3:'物流自动化设备研发经验',
        year_msg4:'ZimaShuttle累计安全运行时长',
        mis_title:'我们的使命',
        mis_msg:'“通过推动供应链仓储从自动化向智慧化的转型，加速可持续制造业的发展。”'
    },
    touch:{
        title:'与我们联系',
        msg:'不断创新，保持敬畏',
        button:'联系方式'
    }
}
