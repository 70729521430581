<template>
  <div class="fontbody">
    <div style="background-color: #000; height: 125px"></div>
    <div class="incontainer">
      <h1 style="text-align: center; font-size: 60px">
        <strong>{{ $t("insight1.title") }}</strong>
      </h1>
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight1.background") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        {{ $t("insight1.backgroundmsg") }}
      </p>
      <img
        style="padding-top: 60px"
        src="../../assets/img/insights/insights-11.png"
        alt=""
      />
      <p style="padding-top: 60px; font-size: 40px">
        <strong>{{ $t("insight1.problems") }}</strong>
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.wastedspace") }}</strong
        >{{ $t("insight1.wastedspacemsg") }}
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.inefficiency") }}</strong
        >{{ $t("insight1.inefficiencymsg") }}
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.safetyhazards") }}</strong
        >{{ $t("insight1.safetyhazardsmsg") }}
      </p>
      <img
        style="padding-top: 60px"
        src="../../assets/img/insights/insights-12.png"
        alt=""
      />
      <p style="text-align: center; padding-top: 60px">
        <strong>{{ $t("insight1.say") }}</strong>
      </p>
      <p style="text-align: center">
        <strong>{{ $t("insight1.author") }}</strong>
      </p>
      <p style="padding-top: 60px; font-size: 40px">
        <strong
          >{{ $t("insight1.result_1") }}<span class="fontbody">ZimaShuttle</span
          >{{ $t("insight1.result_2") }}</strong
        >
      </p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.result1") }}</strong>
      </p>
      <p style="font-size: 20px">{{ $t("insight1.resultmsg1") }}</p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.result2") }}</strong>
      </p>
      <p style="font-size: 20px">{{ $t("insight1.resultmsg2") }}</p>
      <p style="padding-top: 30px; font-size: 20px">
        <strong>{{ $t("insight1.result3") }}</strong>
      </p>
      <p style="font-size: 20px">{{ $t("insight1.resultmsg3") }}</p>
    </div>
    <GetInTouch style="margin-top: 120px"></GetInTouch>
    <BottomBar></BottomBar>
    <!-- >>>>>>> 9109c9437f0733ae06ac7d9e0b30e19c8107c91d -->
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";
export default {
  components: { BottomBar },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.getElementById("/home").style.color = "#fff";
    document.getElementById("/shuttle").style.color = "#fff";
    document.getElementById("/insight").style.color = "#f7b32c";
    document.getElementById("/contact").style.color = "#fff";
    document.getElementById("//home").style.color = "#fff";
    document.getElementById("//shuttle").style.color = "#fff";
    document.getElementById("//insight").style.color = "#f7b32c";
    document.getElementById("//contact").style.color = "#fff";
  },
};
</script>

<style lang="less" scoped>
h2 {
  margin-top: 32px;
  font-size: 28px;
  font-weight: bold;
}

.incontainer {
  // <<<<<<< HEAD
  //   width: auto;

  //   padding: 200px 20%;
  //   // background-color: pink;
  //  h1 {
  //     font-size: 48px;
  //     font-weight: bold;
  //     text-align: center;
  // =======
  padding: 125px 30% 100px;
  background-color: white;

  h1 {
    font-size: 40px;
    // >>>>>>> 9109c9437f0733ae06ac7d9e0b30e19c8107c91d
  }

  p {
    padding: 10px 2%;
    font-size: 24px;
  }

  // <<<<<<< HEAD
  // }
  // .msgpart{
  //   margin:24px 0 48px;
  //   h2{
  //     margin-bottom: 16px;
  //   }
  // }
  // .say{
  //    margin-top: 48px;
  //    margin-bottom: 48px;
  //   p{
  //     text-align: center;
  //      font-size: 32px;
  //   }
  //  & p:nth-child(2){
  //   margin-top: 32px;
  //     text-align: center;
  //      font-size: 32px;
  //   }
  // }
  // .msgcontent{
  //     margin: 20px 0;
  //     padding: 20px 0;
  //       border-top: 2px gray solid;
  //      h2 {
  //     font-size: 24px;
  //   }
  //   p {
  //     font-size: 20px;
  //   }
  // =======
  // >>>>>>> 9109c9437f0733ae06ac7d9e0b30e19c8107c91d
  img {
    width: 1200px;
    object-fit: contain;
  }
}

.fonttitle {
  //letter-spacing: 0px;
}

.fonttitle1 {
  font-family: "OpenSans";
  //letter-spacing: 0px;
}

.fontwe {
  //letter-spacing: 3px;
}

.fontbody {
  font-family: "OpenSans";
  //letter-spacing: 2px;
}
</style>
