import Vue from 'vue/dist/vue.min.js';
import VueI18n from 'vue-i18n/dist/vue-i18n';
import Cookies from 'js-cookie';

Vue.use(VueI18n);
let language
let browserLanguage = window.navigator.language.split('').slice(0,2).join('');
if(browserLanguage=='zh'){
     language ='zh';
Cookies.set( 'language' , language )
}else if(browserLanguage=='en'){
     language =  'en';
    Cookies.set( 'language' , language )
}else{
     language = Cookies.get('language') || 'en';
    Cookies.set( 'language' , language )
}
// console.log(browserLanguage.slice(0,2).join(''));
// 存储在本地,刷新后不会丢失


const i18n = new VueI18n({
locale: language, //  语言标识
messages: {
zh: require('./zh'), // 中文
// 'zh-hk': require('./config/zh-hk'), // 中文繁体-香港
en: require('./en'), // 英文
}
});

export default i18n;
