<template>
  <div class="container fontbody" id="top">
    <div ref="screenbox" class="screenbox">
      <img
        style="
          width: 100%;
          height: 100%;
          transform: scale(0.7);
          /*margin: auto;*/
          padding-top: 90px;
          padding-right: 23px;
          object-fit: contain;
        "
        src="../assets/img/shuttle/shuttle_bg.png"
        alt=""
      />
      <img
        v-show="isgonext"
        class="animate-bounce gonext cursor-pointer"
        style="display: flex; z-index: 0"
        src="../assets/img/nextpage.png"
        alt=""
        @click="gonext"
      />
    </div>
    <div style="height: 200px;background-color: rgb(242, 242, 242);"></div>
    <div class="screenbox2 flex justify-center items-center">
      <div class="cursor-pointer" style="height: 20px; width: 20px;margin-left: 50px">
        <img
          src="../assets/img/shuttle/leftNT.png"
          @click="arrowClickNT('left')"
        />
      </div>
      <el-carousel
        class="cImg"
        arrow="never"
        indicator-position="none"
        :interval="5000"
        ref="NTShow"
      >
        <el-carousel-item v-for="item in imgwrapNT" :key="item.url">
          <img :src="item.url"/>
        </el-carousel-item>
      </el-carousel>
      <div class="cursor-pointer" style="height: 20px; width: 20px">
        <img
          src="../assets/img/shuttle/rightNT.png"
          @click="arrowClickNT('right')"
        />
      </div>
      <div class="fonttitle" style="text-align: left;background-color: white;height: 400px;width: 660px;padding-left: 100px;margin-left: 50px;margin-right: 30px">
        <img style="width: 400px;margin-top: 80px" src="../assets/img/shuttle/title_NT.png">
<!--        <span class="fonttitle1 text" style="color: #f7b52c; font-size: 48px"-->
<!--          ><strong-->
<!--            >Zima<span style="color: #656464">Shuttle - NT</span></strong-->
<!--          ></span-->
<!--        ><br />-->
        <span class="fontbody text1" style="color: #656464"
          ><strong>{{ $t("shuttle.introduce.title1") }}</strong></span
        >
        <p class="fontbody text" style="color: #4d4d4d; margin-top: 50px">
          {{ $t("shuttle.introduce.msg1") }}<br />{{
            $t("shuttle.introduce.msg11")
          }}
        </p>
      </div>
    </div>
    <div class="screenbox3 flex justify-center items-center">
      <div class="fonttitle" style="text-align: left;background-color: white;height: 400px;width: 660px;padding-left: 120px;margin-right: 40px">
        <img style="width: 400px;margin-top: 80px" src="../assets/img/shuttle/title_LT.png">
        <span class="fontbody text1" style="color: #656464"
          ><strong>{{ $t("shuttle.introduce.title2") }}</strong></span
        >
        <p class="fontbody text" style="color: #4d4d4d;margin-top: 50px">
          {{ $t("shuttle.introduce.msg2") }}<br />{{
            $t("shuttle.introduce.msg22")
          }}
        </p>
      </div>
      <div
        class="cursor-pointer"
        style="height: 20px; width: 20px"
      >
        <img
          src="../assets/img/shuttle/leftLT.png"
          @click="arrowClickLT('left')"
        />
      </div>
      <el-carousel
        class="cImg"
        arrow="never"
        indicator-position="none"
        :interval="5000"
        ref="LTShow"
      >
        <el-carousel-item v-for="item in imgwrapLT" :key="item.url">
          <img :src="item.url" />
        </el-carousel-item>
      </el-carousel>
      <div class="cursor-pointer" style="height: 20px; width: 20px;margin-right: 30px">
        <img
          src="../assets/img/shuttle/rightLT.png"
          @click="arrowClickLT('right')"
        />
      </div>
    </div>
    <div class="screenbox2 flex justify-center items-center">
      <div class="cursor-pointer" style="height: 20px; width: 20px;margin-left: 50px">
        <img
          src="../assets/img/shuttle/leftEP.png"
          @click="arrowClickEP('left')"
        />
      </div>
      <el-carousel
        class="cImg"
        arrow="never"
        indicator-position="none"
        :interval="5000"
        ref="EPShow"
      >
        <el-carousel-item v-for="item in imgwrapEP" :key="item.url">
          <img :src="item.url" />
        </el-carousel-item>
      </el-carousel>
      <div class="cursor-pointer" style="height: 20px; width: 20px">
        <img
          src="../assets/img/shuttle/rightEP.png"
          @click="arrowClickEP('right')"
        />
      </div>
      <div class="fonttitle" style="text-align: left;background-color: white;height: 400px;width: 660px;padding-left: 100px;margin-left: 50px;margin-right: 30px">
        <img style="width: 400px;margin-top: 80px" src="../assets/img/shuttle/title_EP.png">
        <!--        <span class="fonttitle1 text" style="color: #f7b52c; font-size: 48px"-->
        <!--          ><strong-->
        <!--            >Zima<span style="color: #656464">Shuttle - NT</span></strong-->
        <!--          ></span-->
        <!--        ><br />-->
        <span class="fontbody text1" style="color: #656464"
        ><strong>{{ $t("shuttle.introduce.title3") }}</strong></span
        >
        <p class="fontbody text" style="color: #4d4d4d; margin-top: 50px">
          {{ $t("shuttle.introduce.msg3") }}<br />{{
            $t("shuttle.introduce.msg32")
          }}<br />{{ $t("shuttle.introduce.msg33") }}
        </p>
      </div>
    </div>
    <div style="background-color: rgb(242, 242, 242);height: 200px"></div>
    <div class="screenbox5 flex justify-center items-center fonttitle">
      <div class="text fontbody" style="text-align: left; margin-right: 120px">
        <img style="margin-top: 0px" src="../assets/img/shuttle/msg.png" v-if="msg1">
        <img style="margin-top: 0px" src="../assets/img/shuttle/msg_cn.png" v-if="msg2">
        <button class="fontbody thrbtn" style="margin-top: 100px" @click="$router.push('contact')">
          {{ $t("shuttle.introduce.btn") }}
        </button>
      </div>
      <img
        src="../assets/img/shuttle/pdf_pic.png"
        alt=""
        style="width: 400px"
      />
    </div>
    <div class="screenbox4 flex flex-col justify-center items-center" style="margin-top: 95px">
      <h1
        class="fontwe"
        style="font-size: 48px; color: #4d4d4d"
      >
        <strong>{{ $t("shuttle.pot.title") }}</strong>
      </h1>
      <div class="flex justify-center items-center">
<!--        <div-->
<!--          class="cursor-pointer"-->
<!--          style="-->
<!--            height: 20px;-->
<!--            width: 20px;-->
<!--            padding-bottom: 97px;-->
<!--            margin-right: 40px;-->
<!--          "-->
<!--        >-->
<!--          <img-->
<!--            src="../assets/img/shuttle/leftNT.png"-->
<!--            @click="arrowClicklast('left')"-->
<!--          />-->
<!--        </div>-->
        <el-carousel
          class="potImg1"
          :interval="60000"
          style="width: 1330px"
          arrow="never"
          ref="lastShow"
        >
          <el-carousel-item v-for="item in potList" :key="item.title1">
            <div class="flex justify-center">
              <img :src="item.imgUrl1" alt="" />
              <div class="imgMsg flex flex-col justify-center items-start">
                <h1 style="color: #4d4d4d; font-size: 32px">
                  {{ item.title1 }}
                </h1>
                <p style="text-align: left;padding-top: 80px">{{ item.text1 }}</p>
              </div>
            </div>
            <div
              class="flex justify-center flex-wrap"
              style="padding-top: 150px"
            >
              <div class="imgMsg1 flex flex-col justify-center items-start">
                <h1 style="color: #4d4d4d; font-size: 32px">
                  {{ item.title2 }}
                </h1>
                <p style="text-align: left;padding-top: 80px">{{ item.text2 }}</p>
              </div>
              <img :src="item.imgUrl2" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
<!--        <div-->
<!--          class="cursor-pointer"-->
<!--          style="-->
<!--            height: 20px;-->
<!--            width: 20px;-->
<!--            padding-bottom: 97px;-->
<!--            margin-left: 40px;-->
<!--          "-->
<!--        >-->
<!--          <img-->
<!--            src="../assets/img/shuttle/rightNT.png"-->
<!--            @click="arrowClicklast('right')"-->
<!--          />-->
<!--        </div>-->
      </div>
    </div>
    <img
      v-show="isgotop"
      class="gotop cursor-pointer"
      src="../assets/img/gotop.png"
      alt=""
      @click="gotop"
    />

    <div v-show="dlwindow" class="dlwindow">
      <i class="el-icon-close cursor-pointer" @click="closeshowdl"></i>
      <div class="fontbody">
        <div ref="screenbox4_1" class="screenbox_1 screenbox4_1">
          <div
            class="lastcontent flex items-center"
            style="height: 100%; padding-top: 5%"
          >
            <div style="z-index: 9" class="form flex flex-col justify-center">
              <!--              <p class=" flex justify-center" style="font-size: 40px; padding-top: 10%; padding-bottom: 5%;color: #4d4d4d">-->
              <!--                <strong>{{ $t("index.screen4.title") }}</strong>-->
              <!--              </p>-->
              <div
                class="formmsg"
                style="padding-top: 30px; padding-left: 1.5%"
              >
                <el-row>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      v-model="form.first_name"
                      type="text"
                      required
                      :placeholder="$t('index.screen4.first_name')"
                      @blur="checkNull('first_name')"
                    />
                    <span
                      v-if="check.first_name"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      type="text"
                      v-model="form.last_name"
                      :placeholder="$t('index.screen4.last_name')"
                      @blur="checkNull('last_name')"
                    />
                    <span
                      v-if="check.last_name"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      type="text"
                      v-model="form.company"
                      :placeholder="$t('index.screen4.company')"
                      @blur="checkNull('company')"
                    />
                    <span
                      v-if="check.company"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      type="text"
                      v-model="form.position"
                      :placeholder="$t('index.screen4.position')"
                  /></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="fontbody">
                    <el-select
                      v-model="form.state"
                      @visible-change="selectFocus"
                      @blur="checkNull('state')"
                      @change="checkNull('state')"
                      :placeholder="$t('index.screen4.state')"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <span
                      v-if="check.state"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                  <el-col :span="6">
                    <input
                      style="width: 180px"
                      class="fontbody"
                      type="text"
                      v-model="form.city"
                      @blur="checkNull('city')"
                      :placeholder="$t('index.screen4.city')"
                    />
                    <span
                      v-if="check.city"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                  <el-col :span="6">
                    <input
                      style="width: 180px"
                      class="fontbody"
                      type="text"
                      v-model="form.zip"
                      @blur="checkNull('zip')"
                      :placeholder="$t('index.screen4.zip')"
                    />
                    <span
                      v-if="check.zip"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      type="text"
                      v-model="form.phone"
                      :placeholder="$t('index.screen4.phone')"
                  /></el-col>
                  <el-col :span="12"
                    ><input
                      style="width: 380px"
                      class="fontbody"
                      type="text"
                      v-model="form.mail"
                      @blur="checkNull('mail')"
                      :placeholder="$t('index.screen4.mail')"
                    />
                    <span
                      v-if="check.mail"
                      style="color: red; text-align: left"
                      >{{ $t("index.screen4.contnull") }}</span
                    >
                  </el-col>
                </el-row>
              </div>

              <textarea
                style="margin-top: 50px"
                class="fontbody"
                :placeholder="$t('index.screen4.msg')"
                v-model="form.msg"
                @blur="checkNull('msg')"
              ></textarea>
              <el-row class="flex items-center" style="margin-top: 10px">
                <span
                  v-if="check.checked"
                  style="color: red; text-align: left"
                  >{{ $t("index.screen4.contnull") }}</span
                >
                <el-col :span="1">
                  <el-checkbox
                    style="
                      transform: scale(1.3);
                      margin-left: 10px;
                      margin-bottom: 5px;
                    "
                    @change="checkNull('checked')"
                    v-model="form.checked"
                  ></el-checkbox>
                </el-col>

                <el-col :span="23">
                  <p
                    style="font-size: 20px; text-align: left; margin-left: 10px"
                  >
                    {{ $t("index.screen4.checkedmsg1")
                    }}<a
                      class="cursor-pointer"
                      style="color: #0a0ae2"
                      @click="showdpp"
                      >{{ $t("index.screen4.checkedmsg2") }}</a
                    >{{ $t("index.screen4.checkedmsg3") }}
                  </p>
                </el-col>
              </el-row>

              <button
                class="subtn fontbody"
                style="font-size: 20px; margin-top: 30px; margin-bottom: 10%"
                @click="sendformdown"
              >
                <strong>{{ $t("shuttle.introduce.btn1") }}</strong>
              </button>
            </div>
            <div v-if="dpp" class="dpp">
              <!-- <i class="el-icon-close cursor-pointer" @click="closedpp"></i> -->
              <div class="fontbody">
                <div ref="screenbox4_1" class="screenbox_1 screenbox4_1">
                  <div
                    class="lastcontent flex items-center"
                    style="height: 100%; padding-top: 5%"
                  >
                    <div
                      style="z-index: 9"
                      class="form flex flex-col justify-center"
                    >
                      <div
                        class=""
                        style="
                          padding-top: 30px;
                          padding-left: 1.5%;
                          height: 600px;
                          overflow: auto;
                        "
                      >
                        <h1 style="font-size: 30px">
                          <strong>Privacy Policy</strong>
                        </h1>
                        <br />
                        <h3>
                          Protecting your private information is our priority.
                          This Statement of Privacy applies to Cooper Li, and
                          Zima Dynamic LLC and governs data collection and
                          usage. For the purposes of this Privacy Policy, unless
                          otherwise noted, all references to Zima Dynamic LLC
                          include zimashuttle.us. The Zima Dynamic LLC
                          application is a Website contact info application. By
                          using the Zima Dynamic LLC application, you consent to
                          the data practices described in this statement.
                        </h3>
                        <br />
                        <h1 style="font-size: 20px">
                          <strong
                            >Collection of your Personal Information</strong
                          >
                        </h1>
                        <h3>
                          We do not collect any personal information about you
                          unless you voluntarily provide it to us. However, you
                          may be required to provide certain personal
                          information to us when you elect to use certain
                          products or services. These may include: (a)
                          registering for an account; (b) entering a sweepstakes
                          or contest sponsored by us or one of our partners; (c)
                          signing up for special offers from selected third
                          parties; (d) sending us an email message; (e)
                          submitting your credit card or other payment
                          information when ordering and purchasing products and
                          services. To wit, we will use your information for,
                          but not limited to, communicating with you in relation
                          to services and/or products you have requested from
                          us. We also may gather additional personal or
                          non-personal information in the future.
                        </h3>
                        <br />
                        <h1 style="font-size: 20px">
                          <strong
                            >Sharing Information with Third Parties</strong
                          >
                        </h1>
                        <h3>
                          Zima Dynamic LLC does not sell, rent or lease its
                          customer lists to third parties.
                        </h3>
                        <br />

                        <h3>
                          Zima Dynamic LLC may, from time to time, contact you
                          on behalf of external business partners about a
                          particular offering that may be of interest to you. In
                          those cases, your unique personally identifiable
                          information (e-mail, name, address, telephone number)
                          is not transferred to the third party. Zima Dynamic
                          LLC may share data with trusted partners to help
                          perform statistical analysis, send you email or postal
                          mail, provide customer support, or arrange for
                          deliveries. All such third parties are prohibited from
                          using your personal information except to provide
                          these services to Zima Dynamic LLC, and they are
                          required to maintain the confidentiality of your
                          information.
                        </h3>
                        <br />
                        <h3>
                          Zima Dynamic LLC may disclose your personal
                          information, without notice, if required to do so by
                          law or in the good faith belief that such action is
                          necessary to: (a) conform to the edicts of the law or
                          comply with legal process served on Zima Dynamic LLC
                          or the site; (b) protect and defend the rights or
                          property of Zima Dynamic LLC; and/or (c) act under
                          exigent circumstances to protect the personal safety
                          of users of Zima Dynamic LLC, or the public.
                        </h3>
                        <br />

                        <br />
                        <h1 style="font-size: 20px">
                          <strong>Right to Deletion</strong>
                        </h1>
                        <h3>
                          Subject to certain exceptions set out below, on
                          receipt of a verifiable request from you, we will:
                          <br />• Delete your personal information from our
                          records; and <br />• Direct any service providers to
                          delete your personal information from their records.
                        </h3>
                        <br />

                        <h3>
                          Please note that we may not be able to comply with
                          requests to delete your personal information if it is
                          necessary to:
                          <br />• Complete the transaction for which the
                          personal information was collected, fulfill the terms
                          of a written warranty or product recall conducted in
                          accordance with federal law, provide a good or service
                          requested by you, or reasonably anticipated within the
                          context of our ongoing business relationship with you,
                          or otherwise perform a contract between you and us;
                          <br />• Detect security incidents, protect against
                          malicious, deceptive, fraudulent, or illegal activity;
                          or prosecute those responsible for that activity;
                          <br />• Debug to identify and repair errors that
                          impair existing intended functionality; <br />•
                          Exercise free speech, ensure the right of another
                          consumer to exercise his or her right of free speech,
                          or exercise another right provided for by law; <br />•
                          Comply with the California Electronic Communications
                          Privacy Act; <br />• Engage in public or peer-reviewed
                          scientific, historical, or statistical research in the
                          public interest that adheres to all other applicable
                          ethics and privacy laws, when our deletion of the
                          information is likely to render impossible or
                          seriously impair the achievement of such research,
                          provided we have obtained your informed consent;
                          <br />• Enable solely internal uses that are
                          reasonably aligned with your expectations based on
                          your relationship with us; <br />• Comply with an
                          existing legal obligation; or <br />• Otherwise use
                          your personal information, internally, in a lawful
                          manner that is compatible with the context in which
                          you provided the information.
                        </h3>
                        <br />

                        <h1 style="font-size: 20px">
                          <strong>Children Under Thirteen</strong>
                        </h1>
                        <h3>
                          Zima Dynamic LLC does not knowingly collect personally
                          identifiable information from children under the age
                          of thirteen. If you are under the age of thirteen, you
                          must ask your parent or guardian for permission to use
                          this application.
                        </h3>
                        <br />
                        <h1 style="font-size: 20px">
                          <strong>E-mail Communications</strong>
                        </h1>
                        <h3>
                          From time to time, Zima Dynamic LLC may contact you
                          via email for the purpose of providing announcements,
                          promotional offers, alerts, confirmations, surveys,
                          and/or other general communication. In order to
                          improve our Services, we may receive a notification
                          when you open an email from Zima Dynamic LLC or click
                          on a link therein.
                        </h3>
                        <br />
                        <h3>
                          If you would like to stop receiving marketing or
                          promotional communications via email from Zima Dynamic
                          LLC, you may opt out of such communications by
                          info@zimashuttle.us.
                        </h3>
                        <br />
                        <h1 style="font-size: 20px">
                          <strong>Changes to this Statement</strong>
                        </h1>
                        <h3>
                          Zima Dynamic LLC reserves the right to change this
                          Privacy Policy from time to time. We will notify you
                          about significant changes in the way we treat personal
                          information by sending a notice to the primary email
                          address specified in your account, by placing a
                          prominent notice on our application, and/or by
                          updating any privacy information. Your continued use
                          of the application and/or Services available after
                          such modifications will constitute your: (a)
                          acknowledgment of the modified Privacy Policy; and (b)
                          agreement to abide and be bound by that Policy.
                        </h3>
                        <br />
                        <h1 style="font-size: 20px">
                          <strong>Contact Information</strong>
                        </h1>
                        <h3>
                          Zima Dynamic LLC welcomes your questions or comments
                          regarding this Statement of Privacy. If you believe
                          that Zima Dynamic LLC has not adhered to this
                          Statement, please contact Zima Dynamic LLC at:
                        </h3>
                        <br />
                        <h3>Zima Dynamic LLC</h3>
                        <h3>28 Stearns St.</h3>
                        <h3>Malden, Massachusetts 02148</h3>
                        <br />
                        <h3>Email Address:</h3>
                        <h3>info@zimashuttle.us</h3>
                        <br />

                        <h3>Telephone number:</h3>
                        <h3>+1.781.288.9735</h3>
                        <br />

                        <h3>Effective as of July 25, 2022</h3>
                      </div>
                      <button
                        class="subtn fontbody"
                        style="
                          font-size: 20px;
                          margin-top: 30px;
                          margin-bottom: 10%;
                        "
                        @click="closedpp"
                      >
                        <strong>{{ $t("index.screen4.dpp") }}</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GetInTouch style="margin-top: 40px"></GetInTouch>
    <BottomBar></BottomBar>
  </div>
</template>

<script>
import BottomBar from "@/components/bottomBar.vue";
import img1 from "../assets/img/shuttle/lunbo/1.png";
import img2 from "../assets/img/shuttle/lunbo/2.png";
import img3 from "../assets/img/shuttle/lunbo/3.png";
import img4 from "../assets/img/shuttle/lunbo/4.png";
import img5 from "../assets/img/shuttle/lunbo/5.png";
import img6 from "../assets/img/shuttle/lunbo/6.png";
import img7 from "../assets/img/shuttle/lunbo/7.png";
import img8 from "../assets/img/shuttle/lunbo/8.png";
import img9 from "../assets/img/shuttle/lunbo/9.png";
import img10 from "../assets/img/shuttle/lunbo/10.png";
import qs from "qs";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      options: [
        {
          value: "Alabama",
          label: "Alabama",
        },
        {
          value: "Alaska",
          label: "Alaska",
        },
        {
          value: "Arizona",
          label: "Arizona",
        },
        {
          value: "Arkansas",
          label: "Arkansas",
        },
        {
          value: "California",
          label: "California",
        },
        {
          value: "Colorado",
          label: "Colorado",
        },
        {
          value: "Connecticut",
          label: "Connecticut",
        },
        {
          value: "Delaware",
          label: "Delaware",
        },
        {
          value: "Florida",
          label: "Florida",
        },
        {
          value: "Georgia",
          label: "Georgia",
        },
        {
          value: "Hawaii",
          label: "Hawaii",
        },
        {
          value: "Idaho",
          label: "Idaho",
        },
        {
          value: "Illinois",
          label: "Illinois",
        },
        {
          value: "Indiana",
          label: "Indiana",
        },
        {
          value: "Iowa",
          label: "Iowa",
        },
        {
          value: "Kansas",
          label: "Kansas",
        },
        {
          value: "Kentucky",
          label: "Kentucky",
        },
        {
          value: "Louisiana",
          label: "Louisiana",
        },
        {
          value: "Maine",
          label: "Maine",
        },
        {
          value: "Maryland",
          label: "Maryland",
        },
        {
          value: "Massachusetts",
          label: "Massachusetts",
        },
        {
          value: "Michigan",
          label: "Michigan",
        },
        {
          value: "Minnesota",
          label: "Minnesota",
        },
        {
          value: "Mississippi",
          label: "Mississippi",
        },
        {
          value: "Missouri",
          label: "Missouri",
        },
        {
          value: "Montana",
          label: "Montana",
        },
        {
          value: "Nebraska",
          label: "Nebraska",
        },
        {
          value: "Nevada",
          label: "Nevada",
        },
        {
          value: "New Hampshire",
          label: "New Hampshire",
        },
        {
          value: "New Jersey",
          label: "New Jersey",
        },
        {
          value: "New Mexico",
          label: "New Mexico",
        },
        {
          value: "New York",
          label: "New York",
        },
        {
          value: "North Carolina",
          label: "North Carolina",
        },
        {
          value: "North Dakota",
          label: "North Dakota",
        },
        {
          value: "Ohio",
          label: "Ohio",
        },
        {
          value: "Oklahoma",
          label: "Oklahoma",
        },
        {
          value: "Oregon",
          label: "Oregon",
        },
        {
          value: "Pennsylvania",
          label: "Pennsylvania",
        },
        {
          value: "Rhode Island",
          label: "Rhode Island",
        },
        {
          value: "South Carolina",
          label: "South Carolina",
        },
        {
          value: "South Dakota",
          label: "South Dakota",
        },
        {
          value: "Tennessee",
          label: "Tennessee",
        },
        {
          value: "Texas",
          label: "Texas",
        },
        {
          value: "Utah",
          label: "Utah",
        },
        {
          value: "Vermont",
          label: "Vermont",
        },
        {
          value: "Virginia",
          label: "Virginia",
        },
        {
          value: "Washington",
          label: "Washington",
        },
        {
          value: "Washington D.C.",
          label: "Washington D.C.",
        },
        {
          value: "West Virginia",
          label: "West Virginia",
        },
        {
          value: "Wisconsin",
          label: "Wisconsin",
        },
        {
          value: "Wyoming",
          label: "Wyoming",
        },
      ],
      value: "",
      form: {
        first_name: "",
        last_name: "",
        company: "",
        position: "",
        state: "",
        city: "",
        zip: "",
        phone: "",
        mail: "",
        msg: "",
        checked: false,
      },
      check: {
        first_name: false,
        last_name: false,
        company: false,
        position: false,
        state: false,
        city: false,
        zip: false,
        phone: false,
        mail: false,
        msg: false,
        checked: false,
      },
      cansend: true,
      imgwrapNT: [
        { url: require("../assets/img/shuttle/shuttle_1.png") },
        { url: require("../assets/img/shuttle/shuttle_12.png") },
      ],
      imgwrapLT: [
        { url: require("../assets/img/shuttle/shuttle_2.png") },
        { url: require("../assets/img/shuttle/shuttle_22.png") },
      ],
      imgwrapEP: [
        { url: require("../assets/img/shuttle/shuttle_3.png") },
        { url: require("../assets/img/shuttle/shuttle_32.png") },
      ],
      // potList: [
      //   {
      //     title1: this.$t("shuttle.pot.card1.title1"),
      //     text1: this.$t("shuttle.pot.card1.msg1"),
      //     imgUrl1: img1,
      //     title2: this.$t("shuttle.pot.card1.title2"),
      //     imgUrl2: img2,
      //     text2: this.$t("shuttle.pot.card1.msg2"),
      //   },
      //   {
      //     title1: this.$t("shuttle.pot.card2.title1"),
      //     text1: this.$t("shuttle.pot.card2.msg1"),
      //     imgUrl1: img3,
      //     title2: this.$t("shuttle.pot.card2.title2"),
      //     imgUrl2: img4,
      //     text2: this.$t("shuttle.pot.card2.msg2"),
      //   },
      //   {
      //     title1: this.$t("shuttle.pot.card3.title1"),
      //     text1: this.$t("shuttle.pot.card3.msg1"),
      //     imgUrl1: img5,
      //     imgUrl2: img6,
      //     title2: this.$t("shuttle.pot.card3.title2"),
      //     text2: this.$t("shuttle.pot.card3.msg2"),
      //   },
      //   {
      //     title1: this.$t("shuttle.pot.card4.title1"),
      //     text1: this.$t("shuttle.pot.card4.msg1"),
      //     imgUrl1: img7,
      //     imgUrl2: img8,
      //     title2: this.$t("shuttle.pot.card4.title2"),
      //     text2: this.$t("shuttle.pot.card4.msg2"),
      //   },
      //   {
      //     title1: this.$t("shuttle.pot.card5.title1"),
      //     text1: this.$t("shuttle.pot.card5.msg1"),
      //     imgUrl1: img9,
      //     imgUrl2: img10,
      //     title2: this.$t("shuttle.pot.card5.title2"),
      //     text2: this.$t("shuttle.pot.card5.msg2"),
      //   },
      // ],
      isgotop: false,
      isgonext: true,
      dlwindow: false,
      dpp: false,
      msg1:true,
      msg2:false,
    };
  },
  components: { BottomBar },
  computed: {
    potList: function () {
      return [
        {
          title1: this.$t("shuttle.pot.card1.title1") + "\n ",
          text1: this.$t("shuttle.pot.card1.msg1"),
          imgUrl1: img1,
          title2: this.$t("shuttle.pot.card1.title2") + "\n ",
          imgUrl2: img2,
          text2: this.$t("shuttle.pot.card1.msg2"),
        },
        {
          title1: this.$t("shuttle.pot.card2.title1") + "\n ",
          text1: this.$t("shuttle.pot.card2.msg1"),
          imgUrl1: img3,
          title2: this.$t("shuttle.pot.card2.title2") + "\n ",
          imgUrl2: img4,
          text2: this.$t("shuttle.pot.card2.msg2"),
        },
        {
          title1: this.$t("shuttle.pot.card3.title1") + "\n ",
          text1: this.$t("shuttle.pot.card3.msg1"),
          imgUrl1: img5,
          imgUrl2: img6,
          title2: this.$t("shuttle.pot.card3.title2") + "\n ",
          text2: this.$t("shuttle.pot.card3.msg2"),
        },
        {
          title1: this.$t("shuttle.pot.card4.title1") + "\n ",
          text1: this.$t("shuttle.pot.card4.msg1"),
          imgUrl1: img7,
          imgUrl2: img8,
          title2: this.$t("shuttle.pot.card4.title2") + "\n ",
          text2: this.$t("shuttle.pot.card4.msg2"),
        },
        {
          title1: this.$t("shuttle.pot.card5.title1") + "\n ",
          text1: this.$t("shuttle.pot.card5.msg1"),
          imgUrl1: img9,
          imgUrl2: img10,
          title2: this.$t("shuttle.pot.card5.title2") + "\n ",
          text2: this.$t("shuttle.pot.card5.msg2"),
        },
      ];
    },
  },

  watch: {
    dlwindow: {
      handler: function () {
        if (this.IsPhone() == true) {
          this.$nextTick(() => {
            var modal = document.getElementsByClassName("dlwindow")[0];
            modal.style.position = "absolute";
          });
        }
      },
    },
  },
  mounted() {
    if (this.$Cookies.get("language") === "zh") {
      this.msg1 = false;
      this.msg2 = true;
    }else {
      this.msg2 = false;
      this.msg1 = true;
    }

    this.$eventBus.$on("changeLanguage", (language) => {
      this.msg1 = language !== "zh";
      this.msg2 = language === "zh";
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    window.onscroll = () => {
      // console.log(document.getElementById("app").scrollHeight)
      // console.log(window.scrollY)
      // if(document.getElementById("app").scrollHeight-window.scrollY<window.screen.height){
      //   this.isgotop=true
      // }else{
      //   this.isgotop=false
      // }
      if (window.scrollY > 110) {
        this.isgotop = true;
        this.isgonext = false;
      } else {
        this.isgotop = false;
        this.isgonext = true;
      }
    };
  },
  methods: {
    showdpp() {
      this.dpp = true;
      if (this.IsPhone()) {
        this.$nextTick(() => {
          let node = document.getElementsByClassName("dpp")[0];
          console.dir(node);
          node.style.position = "absolute";
          node.top = 0;
        });
      }
    },
    closedpp() {
      this.dpp = false;
    },
    checkNull(type) {
      if (type == "first_name") {
        this.form.first_name == ""
          ? (this.check.first_name = true)
          : (this.check.first_name = false);
        // this.$message.error("cantnull");
      } else if (type == "last_name") {
        this.form.last_name == ""
          ? (this.check.last_name = true)
          : (this.check.last_name = false);
      } else if (type == "company") {
        this.form.company == ""
          ? (this.check.company = true)
          : (this.check.company = false);
      } else if (type == "state") {
        this.form.state == ""
          ? (this.check.state = true)
          : (this.check.state = false);
      } else if (type == "city") {
        this.form.city == ""
          ? (this.check.city = true)
          : (this.check.city = false);
      } else if (type == "zip") {
        this.form.zip == ""
          ? (this.check.zip = true)
          : (this.check.zip = false);
      } else if (type == "mail") {
        this.form.mail == ""
          ? (this.check.mail = true)
          : (this.check.mail = false);
        if (
          !this.form.mail.match(
            /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          )
        ) {
          this.check.mail = true;

          this.$message({
            showClose: true,
            message: this.$t("index.screen4.emailerror"),
            type: "error",
          });
        }
      } else if (type == "msg") {
        this.form.msg == ""
          ? (this.check.msg = true)
          : (this.check.msg = false);
      } else if (type == "checked") {
        this.form.checked == false
          ? (this.check.checked = true)
          : (this.check.checked = false);
      }
    },
    resetForm() {
      for (let k in this.form) {
        k == "checked" ? (this.form[k] = false) : (this.form[k] = "");
      }
    },
    sendform() {
      let flag = 0;
      for (let k in this.form) {
        this.checkNull(k);
      }
      for (let k in this.check) {
        if (this.check[k] == true) {
          flag++;
        }
      }
      if (flag == 0) {
        let params = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          company: this.form.company,
          position: this.form.position,
          state: this.form.state,
          city: this.form.city,
          zip: this.form.zip,
          phone: this.form.phone,
          mail: this.form.mail,
          msg: this.form.msg,
          checked: this.form.checked,
        };
        for (let k in params) {
          if (k !== "position" && k !== "phone") {
            if (params[k] === "" || params[k] === false) {
              console.log(k);
              console.log(params[k]);
              this.check[k] = true;
              this.cansend = false;
            }
          }
        }

        if (this.cansend) {
          this.$axios.post("/sendinfo", qs.stringify(params)).then((res) => {
            this.$message({
              showClose: true,
              message: this.$t("index.screen4.sendshow"),
              type: "success",
            });
            this.resetForm();
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.sendshow1"),
            type: "error",
          });
        }
      }
    },
    sendformdown() {
      this.cansend = true;
      let flag = 0;
      for (let k in this.form) {
        this.checkNull(k);
      }
      for (let k in this.check) {
        if (this.check[k] == true) {
          flag++;
        }
      }
      console.log(flag);

      if (flag == 0) {
        let params = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          company: this.form.company,
          position: this.form.position,
          state: this.form.state,
          city: this.form.city,
          zip: this.form.zip,
          phone: this.form.phone,
          mail: this.form.mail,
          msg: this.form.msg,
          checked: this.form.checked,
        };
        for (let k in params) {
          if (k !== "position" && k !== "phone") {
            if (params[k] === "" || params[k] === false) {
              console.log(k);
              console.log(params[k]);
              this.check[k] = true;
              this.cansend = false;
            }
          }
        }
        if (this.cansend) {
          this.$axios.post("/sendinfo", qs.stringify(params)).then((res) => {
            this.$message({
              showClose: true,
              message: this.$t("index.screen4.sendshow"),
              type: "success",
            });
            this.resetForm();
          });
          this.closeshowdl();
          this.downPDF();
        } else {
          this.$message({
            showClose: true,
            message: this.$t("index.screen4.sendshow1"),
            type: "error",
          });
        }
      }
    },
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    showdl() {
      this.dlwindow = true;
    },
    closeshowdl() {
      this.dlwindow = false;
    },
    downPDF() {
      let currentLang = Cookies.get("language");
      if (currentLang == "en") {
        window.open("https://www.zimashuttle.us/api/downPDF");
      } else if (currentLang == "zh") {
        window.open("https://www.zimashuttle.us/api/downPDFCN");
      }
      // this.$axios.get("/downPDF").then((res) => {
      //   this.$message({
      //     showClose: true,
      //     message: "begin",
      //     type: "success",
      //   });
      // });
    },
    arrowClickNT(val) {
      if (val === "right") {
        this.$refs.NTShow.next();
      } else {
        this.$refs.NTShow.prev();
      }
    },
    arrowClickLT(val) {
      if (val === "right") {
        this.$refs.LTShow.next();
      } else {
        this.$refs.LTShow.prev();
      }
    },
    arrowClickEP(val) {
      if (val === "right") {
        this.$refs.EPShow.next();
      } else {
        this.$refs.EPShow.prev();
      }
    },
    arrowClicklast(val) {
      if (val === "right") {
        this.$refs.lastShow.next();
      } else {
        this.$refs.lastShow.prev();
      }
    },
    selectFocus(show) {
      if (show) {
        this.$nextTick(() => {
          let node = document.querySelector(".el-select-dropdown");
          node.style.top = "auto";
          node.style.left = "auto";
          console.log(node.style);
        });
      }
    },
    gotop() {
      document
        .getElementsByClassName("screenbox")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    gonext() {
      document
        .getElementsByClassName("screenbox2")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="less" scoped>
.dpp {
  width: 1200px;
  height: 900px;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 10;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 111;
  }
}

.thrbtn {
  background-color: #f7b32c;
  border-radius: 5px;
  border: 2px solid #f7b32c;
  color: #4d4d4d;
  font-size: 24px;
}
.thrbtn:hover {
  background-color: #f7b32c;
  border-radius: 5px;
  //border: 3px solid #f7b32c;
  color: #000000;
  font-size: 24px;
}

.subtn {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  margin-left: 6px;
  padding: 4px 12px;
  background-color: #f7b32c00;
  border: 2px solid #4d4d4d;
  font-size: 24px;
}

.lastcontent {
  height: 80%;
  background-color: #f9c559;
  overflow: hidden;
}

.dlwindow {
  width: 1200px;
  height: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 5;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 11;
  }
}

/deep/.el-carousel__indicators--horizontal {
  position: relative;
  left: 658px;
  //right: 10px;
  bottom: 520px;
  text-align: right;

  .el-carousel__indicator--horizontal button {
    width: 256px;
    height: 4px;
    background: #000000;
    //border-radius: 50%;
    opacity: 0.2;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 256px;
    height: 4px;
    background: #000000;
    opacity: 0.5;
    border-radius: 10%;
  }
}

.el-carousel-item img {
  width: 100%;
  height: 100%;
}

.fonttitle {
  //letter-spacing: 5px;
}

.fonttitle1 {
  font-family: "OpenSans";
  //letter-spacing: 5px;
}

.fontwe {
  //letter-spacing: 3px;
}

.fontbody {
  //letter-spacing: 2px;
}

.container {
  position: relative;
  max-width: 100%;
}

.screenbox {
  //width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0);
  //background: url(../assets/img/shuttle/shuttle_bg.png) no-repeat center/cover;
  //   overflow:hidden ;
}

.screenbox_1 {
  position: relative;
  //   overflow:hidden ;
}

.screenbox_first_1 {
}

.form {
  width: 800px;
  margin: auto;
  justify-content: center;
}

.formmsg input {
  //width: 380px;
  margin-top: 16px;
  color: #4d4d4d;
  border: none;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  outline: none;
}

.form Textarea {
  width: 784px;
  height: 200px;
  margin-top: 240px;
  margin: 24px auto;
  padding: 8px;
  border: none;
  border: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  outline: none;
}

.lineinput {
  border: none;
  outline: none;
  border-bottom: 3px solid #4d4d4d;
}

/deep/.el-checkbox__label {
  color: #4d4d4d;
  font-size: 24px;
}

.screenbox4_1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/img/lastbg.png);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: bottom 10px right 0;
  background-origin: padding-box;
  z-index: 2;
}

/deep/.el-input__inner:hover {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner:focus {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input__inner {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  border-color: #4d4d4d;
}

/deep/.el-select .el-input .el-select__caret {
  color: #4d4d4d;
  font-size: 20px;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

/deep/.el-select {
  width: 95%;
  box-sizing: border-box;
}

/deep/.el-input__inner {
  margin-top: 16px;
  color: #4d4d4d;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
  font-size: 24px;
  padding-left: 0;
  outline: none;

  &::placeholder {
    // color: rgba(77, 77, 77, 0.5) ;
    font-family: "OpenSans";
    ////letter-spacing: 2px;
    //font-size: 26px;
    color: rgba(0, 0, 0, 0.3);
  }
}

/deep/.el-select-dropdown__item.selected {
  color: #ff7412;
}

/deep/.el-input--suffix:hover .el-input__inner {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4d4d4d;

  &::placeholder {
    color: rgba(77, 77, 77, 0.5);
    font-family: "OpenSans";
    //letter-spacing: 2px;
    font-size: 24px;
    font-weight: 100;
  }
}

/deep/.el-checkbox__inner {
  // width:28px;
  // height: 28px;
  border: 1px solid #4d4d4d;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__inner:hover {
  // width:28px;
  // height: 28px;
  border: 1px solid #ffe136;
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #40a0ff00;
  border-color: #4d4d4d;
}

/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #4d4d4d;
}

// 设置下拉框的背景颜色及边框属性；
/deep/.el-select-dropdown {
  // 若不将下拉框的背景颜色设置为：transparent，那么做不出来半透明的效果；
  // 因为其最终的显示为：下拉框有一个背景颜色且下拉框的字体有一个背景颜色，重叠后的效果展示；
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
}

/deep/.el-select-dropdown .el-popper {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
}

// 设置下拉框的字体属性及背景颜色；
.el-select-dropdown__item {
  font-family: "OpenSans";
  //letter-spacing: 2px;
  text-align: left;
  font-size: 26px;
  color: #4d4d4d;
  background-color: #f9c559;
}

// 设置下拉框列表的 padding 值为：0；(即：样式调整)
/deep/.el-select-dropdown__list {
}

// 设置输入框与下拉框的距离为：0; (即：样式调整)
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 7px;
}

// 将下拉框上的小箭头取消；(看着像一个箭头，其实是两个重叠在一起的箭头)
/deep/.el-popper .popper__arrow,
/deep/.el-popper .popper__arrow::after {
  background-color: #f9c559;
  border: 0px solid #4d4d4d;
  //display: none;
}

// 设置鼠标悬停在下拉框列表的悬停色；
.el-select-dropdown__item:hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item.hover {
  background-color: #f7b32c;
}

.el-select-dropdown__item:focus {
  background-color: #f7b32c;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(77, 77, 77, 0.5) !important;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(77, 77, 77, 0.5) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(77, 77, 77, 0.5) !important;
}

.screenbox2 {
  width: 100%;
  height: 400px;

  background: rgb(242, 242, 242);

  .text {
    width: 540px;
    //min-width: 375px;
    padding-right: 90px;
    font-size: 18px;
    text-align: left;
  }

  .text1 {
    width: 600px;
    min-width: 375px;
    font-size: 24px;
    text-align: left;
  }

  //   overflow:hidden ;
}

.screenbox6 {
  width: 100%;
  height: 500px;

  background: rgb(255, 255, 255);

  .text {
    width: 560px;
    min-width: 375px;
    font-size: 20px;
    text-align: left;
    padding-left: 64px;
  }

  .text1 {
    width: 600px;
    min-width: 375px;
    font-size: 20px;
    text-align: justify;
    padding-left: 64px;
  }

  //   overflow:hidden ;
}

.screenbox3 {
  width: 100%;
  height: 400px;

  background: rgb(242, 242, 242);

  .text {
    width: 540px;
    //min-width: 375px;
    padding-right: 90px;
    font-size: 18px;
    text-align: left;
  }

  .text1 {
    width: 600px;
    min-width: 375px;
    font-size: 24px;
    text-align: left;
  }

  //   overflow:hidden ;
}

.screenbox5 {
  width: 100%;
  height: 700px;

  background: rgb(0, 0, 0);

  .text {
    width: 800px;
    min-width: 375px;
    font-size: 20px;
    text-align: justify;
    //padding-left: 64px;
  }

  //   overflow:hidden ;
}

.screenbox4 {
  width: 100%;
  height: 1250px;
     //overflow:hidden ;
}

.text button {
  height: 40px;
  padding: 0px 16px;
  background-color: #f7b52c;
  border-radius: 8px;
}

.gotop {
  width: 80px;
  position: fixed;
  bottom: 8%;
  right: 10%;
  z-index: 99999;
}

.gonext {
  width: 20px;
  position: fixed;
  bottom: 1%;
  right: 50%;
  z-index: 99999;
}

.cImg {
  padding-top: 50px;
  width: 550px;
  height: inherit;

  img {
    transform: scale(0.9);
    //width: 500px;
    height: inherit;
    margin: auto;
  }
}

.potImg1 {
  //width: 1300px;
  height: 1000px;
  margin-top: 120px;

  img {
    width: 500px;
    height: auto;
  }

  .imgMsg {
    font-size: 24px;
    padding-left: 80px;
    padding-right: 5px;
    //width: 800px;
    //white-space: pre;
  }

  .imgMsg1 {
    font-size: 24px;
    padding-right: 80px;
    width: 830px;
    //white-space: pre;
  }

  /deep/.el-carousel__container {
    height: 95%;
  }
}

// @media (max-width: 992px) {
//  .screenbox2 {
//    width: 100%;
//    height: 800px;
//    flex-direction: column;
//    // background: rgb(122, 255, 140);
//    img {
//      display: block;
//      width: 600px;

//      padding-left: 20px;
//      padding-right: 20px;
//      object-fit: contain;
//    }
//    .text {
//      width: 200px;

//      padding-left: 20px;
//      padding-right: 20px;
//    }
//    //   overflow:hidden ;
//  }
//  .screenbox3 {
//    width: 100%;
//    height: 800px;
//    flex-direction: column;
//    // background: rgb(122, 255, 140);
//    img {
//      display: block;
//      width: 600px;

//      padding-left: 20px;
//      padding-right: 20px;
//      object-fit: contain;
//    }
//    .text {
//      width: 200px;
//      padding-bottom: 48px;
//      padding-left: 20px;
//      padding-right: 20px;
//    }
//    //   overflow:hidden ;
//  }
// }
</style>
