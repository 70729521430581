<template>
  <div class="bottombar flex justify-center items-center">
    <div class=" flex justify-between items-center" style="width:1320px;max-width:1320px">
      <div v-if="cooki" class="dpp1">
        <i class="el-icon-close cursor-pointer" @click="closecooki"></i>
        <div class="fontbody">
          <div ref="screenbox4_1" class="screenbox_1 screenbox4_1">
            <div class="lastcontent flex items-center" style="height: 100%; padding-top: 5%">
              <div style="z-index: 9" class="form flex flex-col justify-center">
                <div class="" style="
                  padding-top: 30px;
                  padding-left: 1.5%;
                  height: 600px;
                  overflow: auto;
                ">
                  <h1 style="font-size: 30px">
                    <strong>ZimaShuttle Cookie Policy</strong>
                  </h1>
                  <br />
                  <h3>
                    Please read this cookie policy (“cookie policy”, "policy")
                    carefully before using [zimashuttle.us] website (“website”,
                    "service") operated by [Zima Dynamic LLC] ("us", 'we", "our").
                  </h3>
                  <br />
                  <h1 style="font-size: 20px">
                    <strong>What are cookies?</strong>
                  </h1>
                  <br />
                  <h3>
                    Cookies are simple text files that are stored on your computer
                    or mobile device by a website’s server. Each cookie is unique
                    to your web browser. It will contain some anonymous
                    information such as a unique identifier, website’s domain
                    name, and some digits and numbers.
                  </h3>
                  <br />
                  <h1 style="font-size: 20px">
                    <strong>What types of cookies do we use?</strong>
                  </h1>
                  <br />
                  <h3>
                    Necessary cookies<br />
                    Necessary cookies allow us to offer you the best possible
                    experience when accessing and navigating through our website
                    and using its features. For example, these cookies let us
                    recognize that you have created an account and have logged
                    into that account.
                  </h3>
                  <br />
                  <h3>
                    Functionality cookies<br />
                    Functionality cookies let us operate the site in accordance
                    with the choices you make. For example, we will recognize your
                    username and remember how you customized the site during
                    future visits.
                  </h3>
                  <br />
                  <h3>
                    Analytical cookies<br />
                    These cookies enable us and third-party services to collect
                    aggregated data for statistical purposes on how our visitors
                    use the website. These cookies do not contain personal
                    information such as names and email addresses and are used to
                    help us improve your user experience of the website.
                  </h3>
                  <br />
                  <h1 style="font-size: 20px">
                    <strong>How to delete cookies?</strong>
                  </h1>
                  <br />
                  <h3>
                    If you want to restrict or block the cookies that are set by
                    our website, you can do so through your browser setting.
                    Alternatively, you can visit www.internetcookies.com, which
                    contains comprehensive information on how to do this on a wide
                    variety of browsers and devices. You will find general
                    information about cookies and details on how to delete cookies
                    from your device.
                  </h3>
                  <br />
                  <h1 style="font-size: 20px"><strong>Contacting us</strong></h1>
                  <br />
                  If you have any questions about this policy or our use of
                  cookies, please contact us at info@zimashuttle.us.
                </div>
                <button class="subtn fontbody" style="font-size: 20px; margin-top: 30px; margin-bottom: 10%"
                  @click="closecooki">
                  {{ $t("index.screen4.dpp") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottombarleft flex flex-col items-start"
        style="margin-right: 10%; font-size: 20px;padding-top: 19px">
        <p class="bottom-path" style="">
          <span class="cursor-pointer" id="//home" @click="changeEvent">{{
              $t("bottombar.home")
          }}</span>
          <span class="cursor-pointer" id="//shuttle" @click="changeEvent">{{
              $t("bottombar.shuttle")
          }}</span>
          <span class="cursor-pointer" id="//insight" @click="changeEvent">{{
              $t("bottombar.insight")
          }}</span>
          <span class="cursor-pointer" id="//aboutUs" @click="changeEvent">{{
              $t("bottombar.about")
            }}</span>
          <span class="cursor-pointer fontbottom" @click="changeEvent" id="//FAQ">{{ $t("bottombar.faq") }}</span>
          <span class="cursor-pointer" id="//contact" @click="changeEvent">{{
              $t("bottombar.contact")
          }}</span>

        </p>

        <div style=" text-align: left;padding-top: 238px" class="icon">

          <div style=" padding-left: 16px; font-size: 20px;padding-bottom: 18px;">
            <!-- {{ $t("bottombar.language") }}: -->
            <img src="../assets/img/earth.png" style="width: 20px;display: inline-block;margin-bottom: 3px"></img>
            <span style="margin-left: 15px;" @click="changeLanguage" ref="english"
              class="fontbottom cursor-pointer">EN</span><span style="margin-left: 12px" @click="changeLanguage"
              ref="chinese" class="cursor-pointer">CN</span>
          </div>
          <h2 style="padding-left: 16px;font-size: 16px;color: #656464">Copyright © Zima Dynamic LLC 2022</h2>
        </div>
      </div>
      <div class="bottombarright" style="
        font-size: 24px;
        margin-left: 20%;
        /*padding-bottom: 50px;*/
        color: #fff;
      ">
        <img style="width: 240px; padding-bottom: 100px" src="../assets/img/index3-logo.png" alt="" />
        <div style="padding-bottom: 10px;">
          <span style="color: white;font-size:24px" class="iconfont icon-linkedin cursor-pointer"
            @click="tolink"></span>
          <!-- <span style="color: white" class="iconfont icon-twitter cursor-pointer" @click="totw"></span> -->
          <!-- <span style="color: white" class="iconfont icon-facebook-fill cursor-pointer" @click="tofb"></span> -->
          <span style="color: white;font-size:27px; margin-left:16px;vertical-align: -10%" class="iconfont icon-Youtube-fill cursor-pointer"
            @click="toyoutube"></span>
          <!--          <span-->
          <!--            style="color: white"-->
          <!--            class="iconfont icon-instagram-fill"-->
          <!--          ></span>-->
        </div>
        <h1 class="fontbottom" style="padding-bottom: 10px;font-size: 20px">
          +1.781.288.9735
        </h1>
        <!--      <span>+1&nbsp;XXX-XXX-XXXX</span><br />-->
        <h1 class="fontbottom" style="padding-bottom: 10px;font-size: 20px">
          <a href="mailto:hello@zimashuttle.us">hello@zimashuttle.us</a>
        </h1>
        <h1 class="fontbottom" style="padding-bottom: 20px;font-size: 20px">
          <a href="mailto:service@zimashuttle.us">service@zimashuttle.us</a>
        </h1>
        <p class="fontbottom cursor-pointer" style="font-size: 16px; color: #656464">
          <a @click="showcooki">Handling of cookies</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      cooki: false,
    };
  },
  watch: {
    cooki: {
      handler: function () {
        if (this.IsPhone() == true) {
          this.$nextTick(() => {
            var modal = document.getElementsByClassName("dpp1")[0];

            modal.style.position = "absolute";
            modal.style.bottom = "0";
          });
        }
      },
    },
    "$route.path": {
      handler: function () {
        document.querySelector("[id='/home']").style.color = "#fff";
        document.querySelector("[id='/shuttle']").style.color = "#fff";
        document.querySelector("[id='/insight']").style.color = "#fff";
        document.querySelector("[id='/contact']").style.color = "#fff";
        document.querySelector("[id='/FAQ']").style.color = "#fff";
        document.querySelector("[id='/aboutUs']").style.color = "#fff";
        document.getElementById(this.$route.path).style.color = "#f7b32c";
      },
      deep: true,
    },
  },
  mounted() {
    this.setClass();
    this.$eventBus.$on("changeLanguage", (type) => {
      if (type === 'zh') {
        this.$refs.english.style.setProperty("color", "#fff");
        this.$refs.chinese.style.setProperty("color", "#f7b52c");
      }
      else {
        this.$refs.chinese.style.setProperty("color", "#fff");
        this.$refs.english.style.setProperty("color", "#f7b52c");
      }
    });
    // if (this.$route.path.substr(0,8)==="/insight") {
    //   document.getElementById("//insight").style.color='#f7b32c';
    // }else
    // document.getElementById("//"+this.$route.path).style.color='#f7b32c';
  },
  beforeDestroy() {
    this.$eventBus.$off("changeLanguage")
  },
  methods: {
    showcooki() {
      this.cooki = true;
    },
    closecooki() {
      this.cooki = false;
    },
    tolink() {
      window.open("https://www.linkedin.com/company/zima-dynamic-llc/");
    },
    tofb() {
      window.open("https://www.facebook.com/Zima-Dynamic-LLC-112120171596759");
    },
    toyoutube() {
      window.open("https://www.youtube.com/channel/UC9UYW0pGJdkcIEiGMNgTRVw");
    },
    totw() {
      window.open("https://twitter.com/ZimaDynamicLLC");
    },
    //路由跳转加切换tab style
    changeEvent($e) {
      console.log($e);
      console.log($e.target.parentNode.children);
      let arr = $e.target.parentNode.children;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].nodeName == "SPAN") {
          arr[i].style.color = "#fff";
        }
      }
      $e.target.style.color = "#f7b32c";

      this.$router.push($e.target.id.slice(1));
    },
    // clickDiv(divname){
    //   console.log(divname);
    //   switch (divname){
    //     case 'home':
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: 'index' });
    //       }
    //       document.getElementById("//index").style.color='#f7b32c';
    //       document.getElementById("//shuttle").style.color='#fff';
    //       document.getElementById("//insight").style.color='#fff';
    //       document.getElementById("//contact").style.color='#fff';
    //       break;
    //     case 'shuttle':
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: 'shuttle' });
    //       }
    //       document.getElementById("//index").style.color='#fff';
    //       document.getElementById("//shuttle").style.color='#f7b32c';
    //       document.getElementById("//insight").style.color='#fff';
    //       document.getElementById("//contact").style.color='#fff';
    //       break;
    //     case 'insight':
    //       if (this.$route.path !== divname) {
    //         this.$router.push({ path: 'insight' });
    //       }
    //       document.getElementById("//index").style.color='#fff';
    //       document.getElementById("//shuttle").style.color='#fff';
    //       document.getElementById("//insight").style.color='#f7b32c';
    //       document.getElementById("//contact").style.color='#fff';
    //       break;
    //     case 'contact':
    //       if (this.$route.path !== divname) {
    //         if (this.$route.path === '/index'){
    //           document
    //               .getElementsByClassName("screenbox4")[0]
    //               .scrollIntoView({ behavior: "smooth" });
    //         }else {
    //           this.$router.push({ path: 'contact' });
    //           document.getElementById("//index").style.color='#fff';
    //           document.getElementById("//shuttle").style.color='#fff';
    //           document.getElementById("//insight").style.color='#fff';
    //           document.getElementById("//contact").style.color='#f7b32c';
    //         }
    //       }
    //       break;
    //   }
    //   document
    //       .getElementsByClassName("fontbody")[0]
    //       .scrollIntoView({block: "start"});
    // },
    changeLanguage(e) {
      this.$refs.english.style.setProperty("color", "#FFF");
      this.$refs.chinese.style.setProperty("color", "#FFF");
      console.log(e.target.innerText);
      if (e.target.innerText == "EN") {
        console.log("English");
        this.$i18n.locale = "en";
        Cookies.set("language", "en");
        this.$refs.english.style.setProperty("color", "#f7b52c");
      } else if (e.target.innerText == "CN") {
        this.$i18n.locale = "zh";
        Cookies.set("language", "zh");
        this.$refs.chinese.style.setProperty("color", "#f7b52c");
      }
      this.$eventBus.$emit("changeLanguage", this.$i18n.locale);
      this.switchFont();
      document
        .getElementsByClassName("fontbody")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    justChange() {

    },
    setClass() {
      //根据路由路径初始化
      console.log(this.$route.path);
      if (
        this.$route.path != "/insight_1" &&
        this.$route.path != "/insight_2" &&
        this.$route.path != "/insight_3"
      ) {
        document.getElementById("/" + this.$route.path).style.color = "#f7b32c";
        console.log(document.getElementById(this.$route.path));
      }
      console.log(Cookies.get("language"));
      let currentLang = Cookies.get("language");
      if (currentLang == "en") {
        this.$refs.english.style.setProperty("color", "#f7b52c");
      } else if (currentLang == "zh") {
        this.$refs.chinese.style.setProperty("color", "#f7b52c");
      }
    },
    switchFont() {
      let currentLang = Cookies.get("language");
      if (currentLang == "zh") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "Microsoft JhengHei";
        console.log(document.body.style);
      }
      if (currentLang == "en") {
        // document.body.style.setProperty('font-Family','Agency FB',"important");
        document.body.style.fontFamily = "OpenSans";
        console.log(document.body.style);
      }
    },
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
  },
};
</script>

<style lang="less" scoped>
.dpp1 {
  width: 1200px;
  height: 900px;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  z-index: 10;

  i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 60px;
    z-index: 11;
  }
}

.subtn {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  padding: 4px 12px;
  border: 2px solid #666;
  border-radius: 4px;
  font-size: 20px;
  color: #666;
  transition: all 0.2s;
}
.subtn:hover {
  width: 100px;
  height: 40px;
  line-height: 1;
  margin-top: 16px;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 18px;
  color: #000000;
  border: 2px solid #000000;
}

.form {
  width: 800px;
  margin: auto;
  justify-content: center;
}

.fontbody {
  //letter-spacing: 2px;
}

.screenbox_1 {
  position: relative;
  //   overflow:hidden ;
}

.screenbox4_1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/img/lastbg.png);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: bottom 40px right 0;
  background-origin: padding-box;
  z-index: 2;
}

.lastcontent {
  height: 800px;
  background-color: #f9c559;
  overflow: hidden;
}

.bottombarleft {
  color: #fff;
  font-size: 24px;

  span {
    margin-left: 16px;
  }
}

.fontbottom {
  font-family: "OpenSans";
  //letter-spacing: 2px;
}

.bottombarright {
  margin-left: 80px;
  color: #fff;
  font-size: 24px;
  text-align: left;
}

.bottombar {
  position: relative;
  height: 500px;
  width: 100%;
  background-color: rgba(0, 0, 0);
  z-index: 3;
  //background-image: url(../assets/img/lastbg.png);
  //background-size: 50%;
  //background-repeat: no-repeat;
  //background-position: bottom 100px left 0;
  //background-origin: padding-box;
}

.bottom-path>span {
  transition: color 0.3s;

  &:hover {
    color: #ffce6e !important;
  }

}
</style>
