import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import index from "../views/index.vue";
import shuttle from "../views/shuttle.vue";
import insight from "../views/insight.vue";
import insight_1 from "../views/insights/insight_1.vue";
import insight_2 from "../views/insights/insight_2.vue";
import insight_3 from "../views/insights/insight_3.vue";
import contact from "../views/contact.vue";
import faq from "../views/FAQ.vue";
import aboutUs from "../views/AboutUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/index",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: index,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/shuttle",
    name: "shuttle",
    component: shuttle,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
  },
  {
    path: "/insight",
    name: "insight",
    component: insight,
  },
  {
    path: "/insight_1",
    name: "insight1",
    component: insight_1,
  },
  {
    path: "/insight_2",
    name: "insight2",
    component: insight_2,
  },
  {
    path: "/insight_3",
    name: "insight3",
    component: insight_3,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/FAQ",
    name: "faq",
    component: faq,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
